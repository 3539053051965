import { ModalState } from './types';

const initialModalState: ModalState = {
  modalOpenExportBills: false,
  modalOpenUserAction: false,
  modalOpenTag: false,
  modalIsPublishTag: false,
  modalOpenLoginRestriction: false,
  modalOpenSavedSearch: false,
  modalSavedSearchCriteria: {},
  modalOpenEditSearch: false,
  modalEditSearchAction: 'rename',
  modalOpenSavedSearchAlert: false,
  modalOpenSavedSearchAlertSubscribed: false,
  modalOpenSavedSearchAlertError: false,
  modalOpenReportProblem: false,
};

export default initialModalState;

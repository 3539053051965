import React, { ReactElement } from 'react';
import './Page.scss';

type HeaderProps = {
  title: string;
  description?: string;
  actionButton?: ReactElement;
  footer?: string;
};

const Header = (props: HeaderProps) => {
  const { title, description, actionButton, footer } = props;
  return (
    <div className="header">
      <div className="row m-0">
        <div className="header-top">
          {actionButton && (
            <div className="action-button float-right">{actionButton}</div>
          )}
          <h1 className="title">{title}</h1>
        </div>
        {(description || footer) && (
          <div className="header-bottom">
            <span className="description">{description}</span>
            <span className="right-footer">{footer}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import { UserAction } from '@enview/interface/types/actions/UserAction';
import { Bill, BillDetailed } from '@enview/interface/types/bills/Bill';
import { Tag } from '@enview/interface/types/tags/Tag';
import { Team } from '@enview/interface/types/Team';
import assign from 'lodash-es/assign';
import intersection from 'lodash-es/intersection';
import moment from 'moment';
import { BillAPI, UserActionAPI } from '../../api';
import * as BillHelper from '../../helpers/BillHelper';
import { BillGroup, splitGroupsByType } from '../../models/BillGroup';
import { Action, State, Thunk } from '../@types';
import {
  requestSent,
  requestSucceeded,
  requestSuccess,
  resetRequest,
} from '../RequestDux';
import {
  CLEAR_EXPORT_DATA,
  ExportState,
  SET_EXPORT_BILLS,
  SET_EXPORT_BILL_TEAM_USER_ACTIONS,
  SET_EXPORT_TEAMS,
} from './types';

// REQUEST NAMES
const REQUEST_EXPORT_DETAILED_BILLS = 'RequestExportDetailedBills';
const REQUEST_EXPORT_USER_ACTIONS = 'RequestExportUserActions';
export const billTeamStateKey = (billId: string, teamId: number) =>
  `${billId}-${teamId}-userActions`;

// REDUCER
export default function reducer(state: ExportState, action: Action): ExportState {
  switch (action.type) {
    case SET_EXPORT_BILLS:
      return assign({}, state, { exportBills: action.bills });
    case SET_EXPORT_TEAMS:
      return assign({}, state, { exportTeams: action.teams });
    case SET_EXPORT_BILL_TEAM_USER_ACTIONS:
      // eslint-disable-next-line no-case-declarations
      const updatedExportUserActions = assign({}, state.exportUserActions || {}, {
        [billTeamStateKey(action.billId, action.teamId)]: action.userActions.filter(
          (a: UserAction) => !!a.comment,
        ),
      });
      return assign({}, state, { exportUserActions: updatedExportUserActions });
    case CLEAR_EXPORT_DATA:
      return assign({}, state, {
        exportBills: undefined,
        exportTeams: undefined,
        exportUserActions: undefined,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const setBills = (bills: BillDetailed[]): Action => {
  return {
    type: SET_EXPORT_BILLS,
    bills,
  };
};

const setExportTeams = (teams: Team[]): Action => {
  return {
    type: SET_EXPORT_TEAMS,
    teams,
  };
};

export const setBillTeamUserActions = (
  billId: string,
  teamId: number,
  userActions: UserAction[],
): Action => {
  return {
    type: SET_EXPORT_BILL_TEAM_USER_ACTIONS,
    billId,
    teamId,
    userActions,
  };
};

export const clearExportData = (): Action => {
  return { type: CLEAR_EXPORT_DATA };
};

export const resetLoadExportBills = (): Action => {
  return resetRequest(REQUEST_EXPORT_DETAILED_BILLS);
};

export const resetLoadExportUserActions = (): Action => {
  return resetRequest(REQUEST_EXPORT_USER_ACTIONS);
};

// SELECTORS
export const loadExportBillsSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_EXPORT_DETAILED_BILLS);
};

export const loadExportUserActionsSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_EXPORT_USER_ACTIONS);
};

// THUNKS
const setExportBills = (teamIds: number[], tagIds: number[]): Thunk => {
  return async (dispatch) => {
    let teamBills: Bill[] = [];
    if (teamIds.length > 0) {
      const trackedBillsPromise = dispatch(
        BillAPI.endpoints.getTrackedBills.initiate(undefined),
      );
      const { data: allTrackedBills } = await trackedBillsPromise;
      teamBills = allTrackedBills
        ? allTrackedBills.filter(
            (b: Bill) => intersection(teamIds, b.teams?.map((t) => t.id)).length > 0,
          )
        : [];
      trackedBillsPromise.unsubscribe();
    }

    const taggedBills: Bill[] = [];
    await Promise.all(
      tagIds.map(async (tagId) => {
        const promise = dispatch(BillAPI.endpoints.getTaggedBills.initiate({ tagId }));
        const { data: bills } = await promise;
        if (bills) taggedBills.push(...bills.data);
        return promise.unsubscribe();
      }),
    );

    return dispatch(setBills(BillHelper.uniqueBills(teamBills, taggedBills)));
  };
};

export const setExportBillGroups = (tags: Tag[], billGroups: BillGroup[]): Thunk => {
  return (dispatch, getState) => {
    const [teamIds, tagIds] = splitGroupsByType(billGroups);
    dispatch(setExportBills(teamIds, tagIds));
    const orgUser = getState().account.organizationUser;
    const teams = orgUser ? orgUser.teamMemberships.map((tm) => tm.team) : [];
    teamIds.push(
      ...tags.filter((tag) => tagIds.includes(tag.id)).map((tag) => tag.teamId),
    );
    const selectedTeams = teams.filter((team) => !!team && teamIds.includes(team.id));
    return dispatch(setExportTeams(selectedTeams));
  };
};

export const loadExportBillDetails = (): Thunk => {
  return async (dispatch, getState) => {
    dispatch(requestSent(REQUEST_EXPORT_DETAILED_BILLS));
    const exportBills = getState().exports.exportBills || [];
    const loadedBills: BillDetailed[] = [];
    await Promise.all(
      exportBills.map(async (bill) => {
        if (bill.versions) {
          loadedBills.push(bill);
          return Promise.resolve();
        }
        const promise = dispatch(BillAPI.endpoints.getBill.initiate(bill.id));
        const { data: bills } = await promise;
        if (bills) loadedBills.push(bills);
        return promise.unsubscribe();
      }),
    );
    dispatch(setBills(loadedBills));
    return dispatch(requestSuccess(REQUEST_EXPORT_DETAILED_BILLS));
  };
};

export const loadExportUserActions = (): Thunk => {
  return async (dispatch, getState) => {
    dispatch(requestSent(REQUEST_EXPORT_USER_ACTIONS));
    const bills = getState().exports.exportBills || [];
    const teams = getState().exports.exportTeams || [];
    await Promise.all(
      bills.map(async (bill) => {
        const promise = dispatch(
          UserActionAPI.endpoints.getUserActions.initiate({
            billId: bill.id,
          }),
        );
        const { data: userActions } = await promise;
        if (userActions && userActions.length > 0) {
          const commentUserActions = userActions.filter((ua) => !!ua.comment);
          if (commentUserActions.length === 0) return;
          teams.forEach((team) => {
            const teamComments = commentUserActions.filter((ua) =>
              ua.teams.includes(team.id),
            );
            if (teamComments.length === 0) return;
            dispatch(
              setBillTeamUserActions(
                bill.id,
                team.id,
                teamComments.sort((a, b) => {
                  return moment(a.updatedAt).diff(moment(b.updatedAt));
                }),
              ),
            );
          });
        }
        return promise.unsubscribe();
      }),
    );
    return dispatch(requestSuccess(REQUEST_EXPORT_USER_ACTIONS));
  };
};

import { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { JurisdictionAPI } from '../../api';
import { JurisdictionSessionResult } from '../../api/jurisdiction';
import { SEOHead } from '../../components/head/SEOHead';
import STATES from '../../helpers/states';

type JurisdictionSessionsProps = {
  jurisAbbr: string;
};

const JurisdictionSessions = (props: JurisdictionSessionsProps): ReactElement => {
  const { jurisAbbr } = props;
  const { data: jurisdictionSessions, isLoading } =
    JurisdictionAPI.endpoints.getSessions.useQuery([jurisAbbr]);

  const stateName = STATES[jurisAbbr.toUpperCase()];

  const styles = {
    headCells: {
      style: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
      },
    },
  };

  const columns = [
    {
      name: 'Session',
      selector: 'displayName',
      ignoreRowClick: true,
      maxWidth: '650px',
    },
    {
      name: 'Bill Count',
      selector: 'billCount',
      ignoreRowClick: true,
      maxWidth: '150px',
      cell(row: JurisdictionSessionResult) {
        const displayText = row.billCount;
        return (
          <Link
            to={`/legislative-tracking?jurisdictions=${jurisAbbr}&sessions=${row.displayName}`}
          >
            {displayText ? displayText : '-'}
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <SEOHead
        description={`details on ${stateName} legislative sessions`}
        title={`${stateName} Sessions`}
      />
      <div className="m-4">
        <h2>Available Sessions</h2>
      </div>
      <div className="jurisdictions-sessions-table-wrapper">
        <DataTable
          className={`jurisdiction-sessions-table ${isLoading ? 'disabled' : ''}`}
          columns={columns}
          customStyles={styles}
          data={jurisdictionSessions ?? []}
        />
        {isLoading && (
          <div className="table-spinner-overlay">
            <Spinner animation="border" role="status" />
          </div>
        )}
      </div>
    </>
  );
};

export default JurisdictionSessions;

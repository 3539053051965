import { Ability, AbilityBuilder } from '@casl/ability';
// eslint-disable-next-line import/no-cycle
import store from '../store';
import defineProUserAbilities from './userAbilityProfiles/proUserAbilities';
import defineGetStartedAbilities from './userAbilityProfiles/getStartedAbilities';
import defineOrgAdminAbilities from './userAbilityProfiles/orgAdminAbilities';
import defineLoggedOutAbilities from './userAbilityProfiles/loggedOutAbilities';
import defineFreeUserAbilities from './userAbilityProfiles/freeUserAbilities';
import defineStarterUserAbilities from './userAbilityProfiles/starterUserAbilities';
import { AccountType } from '@enview/interface/types/Organization';
import defineFreeLegacyAbilities from './userAbilityProfiles/freeLegacyUserAbilities';

/**
 * Defines how to detect object's type
 * https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
export function subjectName(item) {
  if (item) {
    return item;
  }
  return 'false';
}

// Export a glossary of constants instead of using string literals everywhere
export const g = {
  // verbs/actions
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  EXPORT: 'export',
  UPGRADE: 'upgrade',
  TRACK: 'track',
  TAG: 'tag',
  SEARCH: 'search',
  SEND: 'send',
  // share to social media
  SHARE: 'share',
  VIEW: 'view',
  PUBLISH: 'publish',
  // nouns/subjects
  ACCOUNT: 'Account',
  BILL: 'Bill',
  BILL_ANNOTATION: 'Bill Annotation',
  BILL_ANNOTATION_TOOLTIP: 'Bill Annotation Tooltip',
  BILL_INTELLIGENCE: 'Bill Intelligence',
  BILL_SEARCH: 'Bill Search',
  BILL_SHORTNAME: 'Bill Shortname',
  COMMITTEE: 'Committee',
  EXPLORE: 'Explore',
  COMMITTEE_STAFF_DIRECTORY: 'Committee Staff Directory',
  PERSON_STAFF_DIRECTORY: 'Legislator Staff Directory',
  BILL_SEARCH_ACTION_FILTER: 'Bill Search Action Filter',
  BILL_SEARCH_INSIGHT_TOPICS_FILTER: 'Bill Search Insight Topics Filter',
  BILL_SEARCH_TRACKED_FILTER: 'Bill Search Filter by Tracked Bills',
  BILL_SUMMARY: 'AI Bill Summary',
  LIMITED_BILL_SUMMARY: 'Limited AI Bill Summary',
  BILL_SUMMARY_END_OF_SESSION: 'AI End of Session Report',
  BILL_VERSION_TO_VERSION_SUMMARY: 'AI Bill Version to Version Summary',
  HEARINGS: 'Hearings',
  TEAM: 'Team',
  TEAM_MFA: 'Team MFA',
  TRACK_BILL_CONFIRM: 'Track Bill Confirm',
  TAGS: 'Tags',
  TABULAR_BILL_LIST: 'Tabular Bill View',
  MOMENTUM: 'Momentum',
  ORG_SELECTOR: 'Org Selector',
  WORKSPACE_SELECTOR: 'Workspace Selector',
  JURISDICTION_RESOURCES: 'Jurisdiction Resources',
  SAVED_SEARCHES: 'Saved Searches',
  // New Sidebar Sections
  SIDEBAR_SEARCH: 'Sidebar Search Section',
  SIDEBAR_EXPLORE: 'Sidebar Explore Section',
  SIDEBAR_WORKSPACE: 'Sidebar Workspace Section',
  SIDEBAR_BILLS: 'Sidebar Bills Section',
  SIDEBAR_TAGS: 'Sidebar Tags Section',
  SIDEBAR_SEARCHES: 'Sidebar Saved Searches Section',
  SIDEBAR_SEARCH_NAV_GROUP: 'Sidebar Search Navigation Group',
  ADV_NOTIFICATION_SETTINGS: 'Advanced Notification Settings',
  FREE_SAVED_SEARCH_NOTIF_SETTINGS: 'Free Saved Search Notification Settings',
};

const ability = new Ability([], { subjectName });

export const determineTopUserAccountStatus = (user) => {
  return user
    ? user.organizations.reduce((status, o) => {
        if (status === 'pro' || o.accountStatus === 'pro') return 'pro';
        if (status === 'trial' || o.accountStatus === 'trial') return 'trial';
        if (status === 'free' || o.accountStatus === 'free') return 'free';
        if (status === AccountType.Starter || o.accountStatus === AccountType.Starter)
          return AccountType.Starter;
        // We have an enum for this, we should enforce it
        if (
          status == AccountType.FreeLegacy ||
          o.accountStatus === AccountType.FreeLegacy
        )
          return AccountType.FreeLegacy;
        return 'get-started';
      }, undefined)
    : undefined;
};

const defineAbilitiesFor = (user) => {
  const { can, rules } = AbilityBuilder.extract();
  const topStatus = determineTopUserAccountStatus(user);

  if (user && user.organizations) {
    can([g.SEND], g.BILL);
    if (user && (topStatus === 'pro' || topStatus === 'trial')) {
      defineProUserAbilities(can);
    } else if (user && topStatus === 'free') {
      defineFreeUserAbilities(can);
    } else if (user && topStatus === AccountType.Starter) {
      defineStarterUserAbilities(can);
    } else if (user && topStatus === AccountType.FreeLegacy) {
      defineFreeLegacyAbilities(can);
    } else if (user && topStatus === 'get-started') {
      defineGetStartedAbilities(can);
    }

    const isOrgAdmin = user.roles.some((role) => role.name === 'org-admin');
    if (isOrgAdmin) {
      defineOrgAdminAbilities(can);
    }
  } else {
    defineLoggedOutAbilities(can);
  }
  return rules;
};

store.subscribe(() => {
  const state = store.getState();
  ability.update(defineAbilitiesFor(state.account.organizationUser));
});

export default ability;

import { g } from '../ability';

export default function defineProUserAbilities(can) {
  can([g.EDIT], g.BILL_ANNOTATION);
  can([g.VIEW, g.CREATE], g.TEAM);
  can([g.VIEW], g.BILL_ANNOTATION_TOOLTIP);
  can([g.VIEW, g.CREATE], g.BILL_ANNOTATION);
  can([g.VIEW, g.SEARCH], g.BILL_INTELLIGENCE);
  can([g.VIEW], g.TRACK_BILL_CONFIRM);
  can([g.VIEW], g.BILL_SEARCH);
  can([g.TRACK, g.TAG, g.EXPORT, g.VIEW], g.BILL);
  can([g.VIEW, g.PUBLISH], g.TAGS);
  can([g.VIEW], g.TABULAR_BILL_LIST);
  can([g.VIEW], g.MOMENTUM);
  can([g.VIEW], g.HEARINGS);
  can([g.VIEW], g.SIDEBAR_SEARCH);
  can([g.VIEW], g.SIDEBAR_EXPLORE);
  can([g.VIEW], g.SIDEBAR_WORKSPACE);
  can([g.VIEW], g.SIDEBAR_BILLS);
  can([g.VIEW], g.SIDEBAR_TAGS);
  can([g.VIEW], g.SIDEBAR_SEARCHES);
  can([g.VIEW], g.SIDEBAR_SEARCH_NAV_GROUP);
  can([g.VIEW], g.COMMITTEE);
  can([g.VIEW], g.BILL_SEARCH_ACTION_FILTER);
  can([g.VIEW], g.BILL_SEARCH_INSIGHT_TOPICS_FILTER);
  can([g.VIEW], g.BILL_SEARCH_TRACKED_FILTER);
  can([g.VIEW], g.BILL_SUMMARY);
  can([g.VIEW], g.BILL_VERSION_TO_VERSION_SUMMARY);
  can([g.VIEW], g.BILL_SUMMARY_END_OF_SESSION);
  can([g.VIEW], g.JURISDICTION_RESOURCES);
  can([g.CREATE], g.BILL_SHORTNAME);
  can([g.VIEW, g.EDIT], g.ADV_NOTIFICATION_SETTINGS);
  can([g.EDIT], g.ORG_SELECTOR);
  can([g.EDIT], g.WORKSPACE_SELECTOR);
  can([g.VIEW], g.EXPLORE);
  can([g.VIEW], g.PERSON_STAFF_DIRECTORY);
  can([g.VIEW], g.COMMITTEE_STAFF_DIRECTORY);
  can([g.CREATE, g.EDIT, g.VIEW], g.SAVED_SEARCHES);
  can([g.SEND], g.BILL);
}

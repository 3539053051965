/* eslint-disable react/require-default-props */
import { Bill, BillAction } from '@enview/interface/types/bills/Bill';
import { BillSummary } from '@enview/interface/types/bills/BillSummary';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTeamMode } from '../../../dux';
import { convertIdToAbbreviatedTitle, getBillAlias } from '../../../helpers/BillHelper';
import { SortBy, SortOrder } from '../../../models/SortOrder';
import BillStatusBadge from '../../LegislativeTracking/BillStatusBadge';
import { SortableDataTableColumn } from '../../LegislativeTracking/TabularBillSearchResults';
import BillTagButton from '../BillTagging/BillTagButton';
import BillTrackingButton from '../BillTracking/BillTrackingButton';
import { OptionType } from '../DropDowns/MultipleSelectDropDown';
import BillTableHeader from './BillTableHeader';

type BillTableProps = {
  results: { data: any; count: number };
  searching: boolean;
  setSearching: (arg: boolean) => void;
  useServerSidePagination?: boolean;
  query?: any;
  setQuery?: (arg: any) => void;
  filterProperties?: {
    show?: boolean;
    filterLabel?: string;
    selectedFilters?: string[];
    filterOptions?: OptionType<string>[];
    setSelectedFilters?: (selection: string[]) => void;
    tagFilterOptions?: OptionType<string>[];
    selectedTags?: string[];
    setSelectedTags?: (tags: string[]) => void;
  };
};

const BillTable = (props: BillTableProps): ReactElement => {
  const {
    results,
    searching,
    setSearching,
    useServerSidePagination,
    filterProperties,
    query,
    setQuery,
  } = props;

  const teamModeId = useSelector(getTeamMode);
  const [selectedRows, setSelectedRows] = useState([] as Bill[]);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    if (typeof setQuery === 'function') setQuery({ pageSize: 10 });
  }, [setQuery]);

  const getMostRecentAction = (bill: BillSummary): BillAction | undefined => {
    const sortedActions = bill.actions ? [...bill.actions].sort(sortActions) : [];
    return sortedActions[0];
  };

  const sortActions = (a: BillAction, b: BillAction): number => {
    const dateDiff = moment.utc(b.actionDate).diff(moment.utc(a.actionDate));
    if (dateDiff === 0) {
      return b.order - a.order;
    }
    return dateDiff;
  };

  const handleSelectedRowsChange = useCallback(
    (state: { selectedRows: Bill[] }) => {
      setSelectedRows(state.selectedRows);
    },
    [setSelectedRows],
  );

  const handleSelectableRowsSelected = useCallback(
    (row: Bill) => {
      return selectedRows.some((selectedRow) => selectedRow.id === row.id);
    },
    [selectedRows, setSelectedRows],
  );

  const handlePageChange = useCallback((pageParam: number) => {
    setLoadingPage(true);
    if (typeof setQuery === 'function') setQuery({ page: pageParam });
  }, []);

  const handlePageSizeChange = useCallback((pageSizeParam: number) => {
    setLoadingPage(true);
    if (typeof setQuery === 'function') setQuery({ pageSize: pageSizeParam });
  }, []);

  useEffect(() => {
    setSearching(false);
    if (results) setLoadingPage(false);
    // Update the selected rows based on the new results data
    setSelectedRows((prevSelectedRows) => {
      const updatedRowSelections =
        results?.data?.filter((result: { id: string }) => {
          return prevSelectedRows.some((selectedRow) => {
            return result.id === selectedRow.id;
          });
        }) ?? [];
      return updatedRowSelections;
    });
  }, [results]);

  const showTableResults = !searching && results && results.count > 0;

  const [sort, setSort] = useState<SortOrder>(
    new SortOrder(SortBy.RECENT_ACTIVITY, false),
  );

  const handleSortChange = useCallback(
    (column: SortableDataTableColumn, sortDirection: 'desc' | 'asc') => {
      const sortColumn = column.sortKey ?? SortBy.RECENT_ACTIVITY;
      const sortOrder = new SortOrder(sortColumn, sortDirection === 'desc');
      if (isEqual(sort, sortOrder)) return;
      setLoadingPage(true);
      setSort(sortOrder);
      if (typeof setQuery === 'function')
        setQuery({
          order: sortOrder.getQueryParam(),
        });
    },
    [sort, setQuery],
  );

  const serverSideProperties = {
    paginationDefaultPage: query?.page ?? 1,
    paginationPerPage: query?.pageSize ?? 10,
    paginationServer: true,
    onSort: (column: IDataTableColumn, direction: 'asc' | 'desc') =>
      handleSortChange(column, direction),
    onChangePage: (pageParam: number) => handlePageChange(pageParam),
    onChangeRowsPerPage: (pageSizeParam: number) => handlePageSizeChange(pageSizeParam),
    paginationServerOptions: { persistSelectedOnSort: true },
  };

  const columns: SortableDataTableColumn[] = useMemo(
    () => [
      {
        name: 'Tracking',
        selector: 'teams',
        ignoreRowClick: true,
        button: true,
        minWidth: '150px',
        cell: (bill: Bill) => <BillTrackingButton bill={bill} hasStatusIndicator />,
      },
      {
        name: 'Tags',
        selector: 'tags',
        ignoreRowClick: true,
        button: true,
        cell: (bill: Bill) => {
          return useMemo(
            () => <BillTagButton bill={bill} hasStatusIndicator />,
            [bill.tags],
          );
        },
      },
      {
        name: 'Bill ID',
        selector: 'id',
        sortable: true,
        maxWidth: '150px',
        sortKey: SortBy.ALPHANUMERICAL,
        cell(bill: Bill) {
          const title = convertIdToAbbreviatedTitle(bill.id);
          const billAlias = getBillAlias(bill.userAliases, teamModeId);
          return (
            <Link
              className="bill-link"
              to={`/legislative-tracking/bill/details/${bill.id}`}
            >
              {title}
              {billAlias ? `(${billAlias.alias})` : ''}
            </Link>
          );
        },
      },
      {
        name: 'Bill Title',
        selector: 'name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '700px',
        sortKey: SortBy.BILL_TITLE,
        cell: (bill: Bill) => {
          // Need to use cell here to apply the custom wrap behavior
          return bill.name;
        },
      },
      {
        name: 'Bill Status',
        selector: 'status',
        sortable: true,
        ignoreRowClick: true,
        button: true,
        minWidth: '200px',
        maxWidth: '200px',
        sortKey: SortBy.BILL_STATUS,
        cell: (bill: Bill) => {
          return (
            <div>
              <BillStatusBadge bill={bill} />
            </div>
          );
        },
      },
      {
        name: 'Session',
        selector: 'session.displayName',
        sortable: true,
        maxWidth: '150px',
        wrap: true,
        sortKey: SortBy.BILL_SESSION,
      },
      {
        name: 'Latest Action',
        selector: (bill) => getMostRecentAction(bill)?.actionDate,
        sortable: true,
        maxWidth: '150px',
        sortKey: SortBy.RECENT_ACTIVITY,
        cell: (bill: BillSummary) => {
          return getMostRecentAction(bill)?.description;
        },
      },
      {
        name: 'Committees',
        selector: (row) =>
          row.committeeMentions.map((x: { name: string }) => x.name).join(', '),
        sortable: true,
        maxWidth: '300px',
        cell: (bill: BillSummary) => {
          return bill.committeeMentions.map((x: { name: string }) => x.name).join(', ');
        },
      },
    ],
    [],
  );

  const getDefaultSort = useCallback((): {
    selector: string;
    direction: 'asc' | 'desc';
  } => {
    const sorderOrderKey = sort?.sortBy ?? SortBy.RECENT_ACTIVITY;
    const selector =
      columns
        .find((column) => column.sortKey === sorderOrderKey)
        ?.selector?.toString() ?? 'actions';
    return { selector, direction: sort?.isReversed ? 'desc' : 'asc' };
  }, [sort, columns]);

  const table: ReactElement = (
    <DataTable
      columns={columns}
      data={results.data}
      defaultSortAsc={getDefaultSort().direction === 'asc'}
      defaultSortField={getDefaultSort().selector}
      disabled={loadingPage}
      noContextMenu
      onSelectedRowsChange={handleSelectedRowsChange}
      pagination
      paginationRowsPerPageOptions={[10, 20, 50]}
      paginationTotalRows={results.count}
      responsive
      selectableRowSelected={handleSelectableRowsSelected}
      selectableRows
      selectableRowsVisibleOnly
      sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
      {...(useServerSidePagination ? serverSideProperties : {})}
    />
  );

  return (
    <>
      <div className="list results-pane">
        <div className="container result-count">
          <BillTableHeader
            filterProperties={filterProperties?.show ? filterProperties : {}}
            results={results}
            selectedRows={selectedRows}
          />
          {loadingPage && (
            <div className="table-spinner-overlay">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
          {showTableResults && (
            <div className="table bill-table filtered-table">{table}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default BillTable;

import React, { MouseEventHandler, ReactElement, useCallback, useMemo } from 'react';
import { translateBillStatus } from '@enview/interface/utils/Language';
import './List.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { BillSummary } from '@enview/interface/types/bills/BillSummary';
import STATES from '../../../helpers/states';
import { getBillAlias } from '../../../helpers/BillHelper';
import BillTrackingButton from '../BillTracking/BillTrackingButton';
import BillTags from '../BillTagging/BillTags';
import { getTeamMode } from '../../../dux/TeamModeDux';
import FeedbackIndicator from '../Icons/FeedbackIndicator';
import { checkPermission } from '../../../dux';
import { g as abilityGlossary } from '../../../config/ability';
import type { Thunk } from '../../../dux/@types';
import Info from '../../../components/svg/InfoCircleIcon';
import { partyString } from '../../../utils';

const BILL_VIEW_PATH = '/legislative-tracking/bill/details/';

type BillListItemProps = {
  bill: BillSummary;
};

type KeyboardAndMouseEventHandler = React.MouseEventHandler<HTMLDivElement> &
  React.KeyboardEventHandler<HTMLDivElement>;

const BillListItem = (props: BillListItemProps): ReactElement => {
  const { bill } = props;
  const history = useHistory();
  const teamModeId = useSelector(getTeamMode);

  const dispatch = useDispatch();
  const canViewMomentum = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.MOMENTUM, true),
    );
  };

  const renderCommittees = useCallback((): ReactElement | undefined => {
    const { committeeMentions } = bill;
    const committeeList: string[] = [];
    committeeMentions.forEach((committeeMention) => {
      if (committeeMention.name) {
        committeeList.push(committeeMention.name);
      }
    });
    if (committeeList.length) {
      return <span>{committeeList.join(', ')}</span>;
    }
    return undefined;
  }, [bill]);

  const renderSessions = useCallback((): ReactElement | undefined => {
    const { sessions } = bill;
    const sessionList: string[] = [];
    sessions.forEach((session) => {
      if (session.displayName) {
        sessionList.push(session.displayName);
      }
    });
    if (sessionList.length) {
      return <span>{sessionList.join(', ')}</span>;
    }
    return undefined;
  }, [bill]);

  const billAlias = getBillAlias(bill.userAliases, teamModeId);
  const billName = billAlias ? `${bill.number} (${billAlias.alias})` : bill.number;
  const billStatus = useMemo(
    () => translateBillStatus(bill.status, bill.state),
    [bill.status, bill.state],
  );
  const billCommittee = useMemo(
    () =>
      (bill.committeeMentions &&
        bill.committeeMentions.length > 0 &&
        renderCommittees()) ||
      '(no committee)',
    [bill.committeeMentions, renderCommittees],
  );
  const billSession = useMemo(
    () => (bill.sessions && bill.sessions.length > 0 && renderSessions()) || '',
    [renderSessions, bill.sessions],
  );
  const { state } = bill.session;
  const countries: { [abbreviation: string]: string } = {
    usa: 'Federal',
    ng: 'Nigeria',
    za: 'South Africa',
  };
  const abbr = state.abbreviation;
  const locationLabel =
    abbr in countries ? countries[abbr] : STATES[abbr.toUpperCase()];

  const hasMomentum = bill.salienceScore === 1;

  const MOMENTUM_TOOLTIP_MESSAGE = `Plural’s experimental machine learning models have identified this as a bill
  with potential momentum, which means it’s likely to or has come up for a passage
  vote by the end of the session. For more information about momentum indicators
  see`;

  const handleBillCardAction: KeyboardAndMouseEventHandler = (e) => {
    e.stopPropagation();

    if (e.type === 'keydown') {
      const keyboardEvent = e as React.KeyboardEvent<HTMLDivElement>;
      if (keyboardEvent.key === 'Enter') {
        history.push(`${BILL_VIEW_PATH}${bill.id}`);
      }
    }

    if (e.type === 'click') {
      history.push(`${BILL_VIEW_PATH}${bill.id}`);
    }

    return;
  };

  const handleTooltipContentClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderSponsors = () => {
    const { sponsors } = bill;
    if (!sponsors) return undefined;
    const currentSponsors = sponsors.filter((s) => !s.isRemoved);
    const primarySponsors = currentSponsors.filter((s) => s.isPrimary);
    const coSponsors = currentSponsors.filter((s) => !s.isPrimary && !s.isRemoved);
    const allSponsors = [...primarySponsors, ...coSponsors];
    const sc = 3;

    return (
      <div className="bill-sponsor-list" data-name="bill-sponsors">
        {allSponsors.slice(0, sc).map((sponsor, index) => {
          const sponsorName = sponsor.name + partyString(sponsor);
          return (
            <span
              className={`bill-sponsor-list-item ${
                sponsor.isPrimary ? 'primary' : 'secondary'
              }`}
              key={index}
              onClick={(e) => e.stopPropagation()}
            >
              {index < allSponsors.length - 1 ? sponsorName + ',' : sponsorName}
            </span>
          );
        })}
        <span
          className="more-sponsors"
          id={`${bill.id}-sponsors`}
          onClick={(e) => e.stopPropagation()}
        >
          {allSponsors.length > sc && (
            <>
              +{allSponsors.length - sc} sponsor{allSponsors.length - sc > 1 ? 's' : ''}
            </>
          )}
          <Info className="info" color="#4a4a4a" size={16} />
        </span>
        <ReactTooltip
          anchorSelect={`#${bill.id}-sponsors`}
          className="tooltip"
          clickable
          delayHide={300}
          key={`tooltip-${bill.name}`}
          place="bottom"
        >
          <div className="sponsors-tooltip" onClick={handleTooltipContentClick}>
            <span className="tooltip-header">Primary sponsors:</span>
            <ul>
              {primarySponsors.map((sponsor, index) => (
                <li key={`primary-${index}`}>
                  {sponsor.person?.id ? (
                    <Link to={`/person/${sponsor.person?.id}`}>
                      {`${sponsor.name}${partyString(sponsor)}`}
                    </Link>
                  ) : (
                    `${sponsor.name}${partyString(sponsor)}`
                  )}
                </li>
              ))}
            </ul>
            {!!coSponsors.length && (
              <>
                <span className="tooltip-header">Co-sponsors:</span>
                <ul>
                  {coSponsors.map((sponsor, index) => (
                    <li key={`co-${index}`}>
                      {sponsor.person?.id ? (
                        <Link to={`/person/${sponsor.person?.id}`}>
                          {`${sponsor.name}${partyString(sponsor)}`}
                        </Link>
                      ) : (
                        `${sponsor.name}${partyString(sponsor)}`
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </ReactTooltip>
      </div>
    );
  };

  return (
    <div
      className="card list-item"
      id={bill.id}
      onClick={handleBillCardAction}
      onKeyDown={handleBillCardAction}
      tabIndex={0}
    >
      <div className="card-body">
        <div className="pre-note" data-name="bill-status">
          {billStatus}
        </div>
        <h3 className="title" data-name="bill-state">
          {locationLabel}
        </h3>
        <h3 className="title" data-name="bill-number">
          {billName}
        </h3>
        <h4 className="body" data-name="bill-name">
          {bill.name}
        </h4>
        {renderSponsors()}
        <div className="row footnote">
          <div className="col-md-8 float-left">{billCommittee}</div>
          <div className="col-md-4 float-right session">{billSession}</div>
        </div>
        <BillTags bill={bill} />
        <div className="row text-right bill-tracking-btn">
          {hasMomentum && canViewMomentum() && (
            <FeedbackIndicator
              billId={bill.id}
              infoLink="https://help.pluralpolicy.com/what-is-a-momentum-indicator"
              place="top"
              tooltipDescription={MOMENTUM_TOOLTIP_MESSAGE}
              type="momentum"
            />
          )}
          <BillTrackingButton bill={bill} />
        </div>
      </div>
    </div>
  );
};

export default BillListItem;

import React from 'react';

export default (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5446 0H1.45542C0.656666 0 0 0.656666 0 1.45542V14.5446C0 15.3453 0.656666 16 1.45542 16H14.5446C15.3453 16 16 15.3433 16 14.5446V1.45542C16 0.656666 15.3453 0 14.5446 0ZM2.91276 3.63279C2.91276 3.40814 3.05676 3.26413 3.28141 3.26413H6.91228C7.13693 3.26413 7.28093 3.40814 7.28093 3.63279V7.26557C7.28093 7.49022 7.13693 7.63422 6.91228 7.63422H3.27949C3.05484 7.63422 2.91084 7.49022 2.91084 7.26557V3.63279H2.91276ZM11.6318 13.0892C11.6318 13.3138 11.4878 13.4578 11.2632 13.4578H3.26413C3.03948 13.4578 2.89548 13.3138 2.89548 13.0892V12.3691C2.89548 12.1445 3.03948 12.0005 3.26413 12.0005H11.2651C11.4897 12.0005 11.6337 12.1445 11.6337 12.3691V13.0892H11.6318ZM13.0892 10.1764C13.0892 10.4011 12.9452 10.5451 12.7205 10.5451H3.27949C3.05484 10.5451 2.91084 10.4011 2.91084 10.1764V9.45638C2.91084 9.23173 3.05484 9.08772 3.27949 9.08772H12.7359C12.9605 9.08772 13.1045 9.23173 13.1045 9.45638V10.1764H13.0892ZM13.0892 7.28093C13.0892 7.50558 12.9452 7.64959 12.7205 7.64959H9.08772C8.86307 7.64959 8.71907 7.50558 8.71907 7.28093V6.5609C8.71907 6.33625 8.86307 6.19225 9.08772 6.19225H12.7205C12.9452 6.19225 13.0892 6.33625 13.0892 6.5609V7.28093ZM13.0892 4.36817C13.0892 4.59282 12.9452 4.73683 12.7205 4.73683H9.08772C8.86307 4.73683 8.71907 4.59282 8.71907 4.36817V3.64815C8.71907 3.4235 8.86307 3.27949 9.08772 3.27949H12.7205C12.9452 3.27949 13.0892 3.4235 13.0892 3.64815V4.36817Z"
      fill="#27A4CC"
    />
  </svg>
);

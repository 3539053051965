import { BillSearchCriteria, SavedSearch } from '@enview/interface/types/BillSearch';
import FeedbackData from '@enview/interface/types/actions/FeedbackData';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import { Tag } from '@enview/interface/types/tags/Tag';
import { BillGroup } from '../../models/BillGroup';

export type SavedSearchEditAction = 'update' | 'archive' | 'rename';

// STATE SLICE
export type ModalState = {
  modalOpenUserAction: boolean;
  modalUserAction?: Partial<UserAction>;
  modalOpenExportBills: boolean;
  modalPreselectedBillGroups?: BillGroup[];
  modalOpenTag: boolean;
  modalTag?: Tag;
  modalIsPublishTag: boolean;
  modalOpenLoginRestriction: boolean;
  modalOpenSavedSearch: boolean;
  modalSavedSearchCriteria: Partial<BillSearchCriteria>;
  modalOpenEditSearch: boolean;
  modalSavedSearch?: SavedSearch;
  modalEditSearchAction: SavedSearchEditAction;
  modalOpenSavedSearchAlert: boolean;
  modalOpenSavedSearchAlertSubscribed: boolean;
  modalOpenSavedSearchAlertError: boolean;
  modalOpenReportProblem: boolean;
  modalReportProblemData?: FeedbackData;
};

// ACTIONS
export const MODAL_OPEN_USER_ACTION = 'modal/openUserAction';
export const MODAL_CLOSE_USER_ACTION = 'modal/closeUserAction';
export const MODAL_OPEN_EXPORT_BILLS = 'modal/openExportBills';
export const MODAL_CLOSE_EXPORT_BILLS = 'modal/closeExportBills';
export const MODAL_OPEN_TAG = 'modal/openTag';
export const MODAL_CLOSE_TAG = 'modal/closeTag';
export const MODAL_OPEN_LOGIN_RESTRICTION = 'modal/openLoginRestriction';
export const MODAL_CLOSE_LOGIN_RESTRICTION = 'modal/closeLoginRestriction';
export const MODAL_OPEN_SAVED_SEARCH = 'modal/openSavedSearch';
export const MODAL_CLOSE_SAVED_SEARCH = 'modal/closeSavedSearch';
export const MODAL_OPEN_EDIT_SEARCH = 'modal/openEditSearch';
export const MODAL_CLOSE_EDIT_SEARCH = 'modal/closeEditSearch';
export const MODAL_OPEN_SAVED_SEARCH_ALERT = 'modal/openSavedSearchAlert';
export const MODAL_CLOSE_SAVED_SEARCH_ALERT = 'modal/closeSavedSearchAlert';
export const MODAL_OPEN_REPORT_PROBLEM = 'modal/openReportProblem';
export const MODAL_CLOSE_REPORT_PROBLEM = 'modal/closeReportProblem';

export type ModalOpenUserAction = {
  type: typeof MODAL_OPEN_USER_ACTION;
  userAction?: Partial<UserAction>;
};

export type ModalCloseUserAction = {
  type: typeof MODAL_CLOSE_USER_ACTION;
};

export type ModalOpenExportBillsAction = {
  type: typeof MODAL_OPEN_EXPORT_BILLS;
  preselectedBillGroups?: BillGroup[];
};

export type ModalCloseExportBillsAction = {
  type: typeof MODAL_CLOSE_EXPORT_BILLS;
};

export type ModalOpenTag = {
  type: typeof MODAL_OPEN_TAG;
  tag?: Tag;
  isPublish: boolean;
};

export type ModalCloseTag = {
  type: typeof MODAL_CLOSE_TAG;
};

export type ModalOpenLoginRestriction = {
  type: typeof MODAL_OPEN_LOGIN_RESTRICTION;
};

export type ModalCloseLoginRestriction = {
  type: typeof MODAL_CLOSE_LOGIN_RESTRICTION;
};

export type ModalOpenSavedSearch = {
  type: typeof MODAL_OPEN_SAVED_SEARCH;
  criteria: BillSearchCriteria;
};

export type ModalCloseSavedSearch = {
  type: typeof MODAL_CLOSE_SAVED_SEARCH;
};

export type ModalOpenEditSearch = {
  type: typeof MODAL_OPEN_EDIT_SEARCH;
  search: SavedSearch;
  editAction: SavedSearchEditAction;
};

export type ModalCloseEditSearch = {
  type: typeof MODAL_CLOSE_EDIT_SEARCH;
};

export type ModalOpenSavedSearchAlert = {
  type: typeof MODAL_OPEN_SAVED_SEARCH_ALERT;
  isError: boolean;
  isSubscribed: boolean;
};

export type ModalCloseSavedSearchAlert = {
  type: typeof MODAL_CLOSE_SAVED_SEARCH_ALERT;
};

export type ModalOpenReportProblem = {
  type: typeof MODAL_OPEN_REPORT_PROBLEM;
  data: FeedbackData;
};

export type ModalCloseReportProblem = {
  type: typeof MODAL_CLOSE_REPORT_PROBLEM;
};

export type Action =
  | ModalOpenUserAction
  | ModalCloseUserAction
  | ModalOpenExportBillsAction
  | ModalCloseExportBillsAction
  | ModalOpenTag
  | ModalCloseTag
  | ModalOpenLoginRestriction
  | ModalCloseLoginRestriction
  | ModalOpenSavedSearch
  | ModalCloseSavedSearch
  | ModalOpenEditSearch
  | ModalCloseEditSearch
  | ModalOpenSavedSearchAlert
  | ModalCloseSavedSearchAlert
  | ModalOpenReportProblem
  | ModalCloseReportProblem;

import {
  OrganizationUser,
  OrganizationUserRole,
} from '@enview/interface/types/OrganizationUser';
import { faBars, faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  PropsWithChildren,
  ReactElement,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { TeamAPI } from '../../api';
import Logo from '../../components/Logo';
import MagnifyingGlass from '../../components/svg/MagnifyingGlass';
import QuestionMarkCircle from '../../components/svg/QuestionMarkCircle';
import UsersIcon from '../../components/svg/UsersIcon';
import { getPendingTeamInvitesForUser, getTeamMode, logout } from '../../dux';
import { State } from '../../dux/@types';
import { setOrgId } from '../../dux/TeamModeDux';
import featureFlags, { FrontendFeature } from '../../featureFlags';
import { TeamInvitation } from '../../models/Team';
import MasqueradeUserSessionOn from '../Admin/MasqueradeUserSessionOn';
import NeedToUpgrade from '../Permissions/NeedToUpgrade';
import '../Regulations/Regulations.scss';
import CompassIcon from '../Shared/Icons/CompassIcon';
import UserIcon from '../Shared/Icons/UserIcon';
import EditSavedSearchModal from '../Shared/Modals/EditSavedSearchModal';
import ReportProblemModal from '../Shared/Modals/ReportProblemModal';
import { SavedSearchAlertModal } from '../Shared/Modals/SavedSearchAlertsModal';
import SavedSearchModal from '../Shared/Modals/SavedSearchModal';
import NewPrimarySidebar from './NavRedesign/NewPrimarySidebar';
import NewTopNav from './NavRedesign/TopNav/NewTopNav';
import PrimarySidebar from './PrimarySidebar';
import './PrimaryView.scss';

const TeamInvitationRequests = lazy(() => import('../TeamView/TeamInvitationRequests'));
const ExportBillsModal = lazy(
  () => import('../Shared/Modals/ExportBills/ExportBillsModal'),
);
const UserActionModal = lazy(
  () => import('../Shared/Modals/UserAction/UserActionModal'),
);
const TagModal = lazy(() => import('../Shared/Modals/Tag/TagModal'));

type PrimaryLayoutProps = {
  activePath: string;
  // TODO: Make this optional to display logged out state
  organizationUser: OrganizationUser;
};

const PrimaryLayout = (props: PropsWithChildren<PrimaryLayoutProps>): ReactElement => {
  const { activePath, organizationUser, children } = props;
  let orgId: number | undefined;
  const teamModeId = useSelector(getTeamMode);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarMinimized, setSidebarMinimized] = useState(true);
  // const [showNotificationIndicator, setShowNotificationIndicator] = useState(false);

  const modalOpenTag = useSelector((state: State) => state.modals.modalOpenTag);
  const modalOpenUserAction = useSelector(
    (state: State) => state.modals.modalOpenUserAction,
  );
  const modalOpenExportBills = useSelector(
    (state: State) => state.modals.modalOpenExportBills,
  );
  const openRestrictionModal = useSelector(
    (state: State) => state.modals.modalOpenLoginRestriction,
  );
  const isSavedSearchModalOpen = useSelector(
    (state: State) => state.modals.modalOpenSavedSearch,
  );
  const isEditSavedSearchModalOpen = useSelector(
    (state: State) => state.modals.modalOpenEditSearch,
  );
  const isMasqueradeSession = useSelector(
    (state: State) => state.authentication.isMasqueradeSession,
  );
  const pendingTeamInvitations = useSelector(
    (state: State) => state.teams.pendingTeamInvitations,
  );
  const isSavedSearchAlertModalOpen = useSelector(
    (state: State) => state.modals.modalOpenSavedSearchAlert,
  );
  const isReportProblemModalOpen = useSelector(
    (state: State) => state.modals.modalOpenReportProblem,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const useNewTopNav = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.TopNavRedesign,
    organizationUser,
  );

  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);
  if (teamMemberships) {
    const currentTeam = teamMemberships.find((m) => m.teamId === teamModeId)?.team;
    orgId = currentTeam?.organizationId;
  }

  useEffect(() => {
    dispatch(getPendingTeamInvitesForUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setOrgId(orgId));
  }, [orgId]);

  const renderBannerContent = (
    pendingTeamInvitationsArg: TeamInvitation[],
    isMasqueradeSessionArg: boolean,
  ): ReactElement | undefined => {
    if (pendingTeamInvitationsArg.length) {
      return <TeamInvitationRequests />;
    }
    if (isMasqueradeSessionArg) {
      return <MasqueradeUserSessionOn />;
    }

    return undefined;
  };

  const dropDownClass = useMemo(
    () =>
      menuOpen ? 'dropdown desktop-menu active' : 'dropdown desktop-menu inactive',
    [menuOpen],
  );
  const isEnviewAdmin = useMemo(
    () =>
      organizationUser.roles.find(
        (role: OrganizationUserRole) => role.name === 'enview-admin',
      ),
    [organizationUser.roles],
  );

  const isOrgAdmin = useMemo(
    () =>
      !!organizationUser.roles.find(
        (role) => role.name === 'org-admin' && role.organizationId === orgId,
      ),
    [orgId, organizationUser],
  );

  const logoutUser = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    dispatch(logout());
    history.replace('/');
  };

  const useNewSidebar = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.SidebarNavRedesign,
    organizationUser,
  );

  const renderRightNav = (): ReactElement => {
    return (
      <div className="right-nav">
        <ul>
          <li
            className={`nav-icon help-link${
              activePath.includes('/jurisdictions') ? ' selected' : ''
            }`}
            id="jurisdictions-nav-button"
          >
            <Link to="/jurisdictions">
              <CompassIcon />
            </Link>
            <div className="selected-line" />
          </li>
          <li
            className={`nav-icon help-link${
              activePath.includes('/teams') ? ' selected' : ''
            }`}
            id="team-nav-button"
          >
            <Link to="/teams">
              <UsersIcon />
            </Link>
            <div className="selected-line" />
          </li>
          <li
            className={`nav-icon help-link${
              activePath.includes('/legislative-tracking') ? ' selected' : ''
            }`}
            id="bills-nav-button"
          >
            <Link to="/legislative-tracking">
              <MagnifyingGlass height="20" width="21" />
            </Link>
            <div className="selected-line" />
          </li>
          {/* Hiding /notifications until we make updates to it */}
          {/* <li
              className={`nav-icon help-link${
                activePath === '/notifications' ? ' selected' : ''
              }`}
            >
              <Link
                onClick={() => setShowNotificationIndicator(false)}
                to="/notifications"
              >
                <Bell />
              </Link>
              <div className="selected-line" />
            </li> */}
          <li className="nav-icon help-link">
            <a
              href="https://help.pluralpolicy.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <QuestionMarkCircle />
            </a>
          </li>
          <li className="nav-icon help-link" />
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li
            className={dropDownClass}
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ cursor: 'pointer' }}
          >
            <div className="desktop-menu-label">
              <UserIcon
                firstName={organizationUser.firstName}
                lastName={organizationUser.lastName}
              />
            </div>
            <FontAwesomeIcon
              className="mobile-menu-icon"
              color="#979797"
              icon={faEllipsisV}
            />
            <ul style={{ minWidth: '12rem', right: 0 }}>
              <li className="mobile-only">
                <Link to="/teams">Home</Link>
              </li>
              <li className="mobile-only">
                <Link to="/legislative-tracking">Search</Link>
              </li>
              <li className="mobile-only">
                <a
                  href="https://help.pluralpolicy.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Help
                </a>
              </li>
              {/* <li className="mobile-only">
                  <Link
                    onClick={() => setShowNotificationIndicator(false)}
                    to="/notifications"
                  >
                    Notifications
                  </Link>
                </li> */}
              {isEnviewAdmin && (
                <li>
                  <Link
                    id="admin-dashboard"
                    style={{ whiteSpace: 'nowrap' }}
                    to="/admin"
                  >
                    Admin Dashboard
                  </Link>
                </li>
              )}
              <li>
                <Link
                  id="account-settings"
                  style={{ whiteSpace: 'nowrap' }}
                  to="/account/settings"
                >
                  Account Settings
                </Link>
              </li>
              {isOrgAdmin && (
                <li>
                  <Link
                    id="org-settings"
                    style={{ whiteSpace: 'nowrap' }}
                    to="/organization/members"
                  >
                    Organization Settings
                  </Link>
                </li>
              )}
              <li>
                <Link
                  id="notification-settings"
                  style={{ whiteSpace: 'nowrap' }}
                  to="/account/notifications"
                >
                  Notification Settings
                </Link>
              </li>
              <li>
                <Link id="all-tags" style={{ whiteSpace: 'nowrap' }} to="/account/tags">
                  All Tags
                </Link>
              </li>
              <br />
              <li id="log-out" style={{ whiteSpace: 'nowrap' }}>
                <button className="btn-link" onClick={logoutUser} type="button">
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const primaryWrapperClass = useMemo(
    () =>
      `primary-layout-wrapper${
        sidebarMinimized ? ' sidebar-minimized' : ''
      }${' d-flex flex-row'}`,
    [sidebarMinimized],
  );

  const bannerContent = renderBannerContent(
    pendingTeamInvitations || [],
    isMasqueradeSession || false,
  );

  const layoutClasses = ['primary-layout'];
  if (bannerContent) {
    layoutClasses.push('with-banner');
  }
  return (
    <div className={layoutClasses.join(' ')}>
      <div className="site-banner">{bannerContent}</div>
      {useNewTopNav && (
        <NewTopNav
          isEnviewAdmin={!!isEnviewAdmin}
          isOrgAdmin={isOrgAdmin}
          organizationUser={organizationUser}
          setSidebarMinimized={setSidebarMinimized}
          sidebarMinimized={sidebarMinimized}
        />
      )}
      {!useNewTopNav && (
        <nav className="d-flex flex-row ce-nav">
          {sidebarMinimized && (
            <FontAwesomeIcon
              className="mobile-menu-icon"
              color="#979797"
              icon={faBars}
              onClick={() => setSidebarMinimized(false)}
            />
          )}
          {!sidebarMinimized && (
            <FontAwesomeIcon
              className="mobile-menu-icon"
              color="#979797"
              icon={faTimes}
              onClick={() => setSidebarMinimized(true)}
            />
          )}
          <div className="branding">
            <Link to="/legislative-tracking">
              <Logo />
            </Link>
          </div>
          {renderRightNav()}
        </nav>
      )}
      <div className={primaryWrapperClass}>
        {!useNewSidebar && (
          <PrimarySidebar
            isMinimized={sidebarMinimized}
            minimize={() => setSidebarMinimized(true)}
          />
        )}
        {useNewSidebar && (
          <NewPrimarySidebar
            isMinimized={sidebarMinimized}
            minimize={() => setSidebarMinimized(true)}
          />
        )}
        {modalOpenExportBills && <ExportBillsModal />}
        {modalOpenUserAction && <UserActionModal />}
        {modalOpenTag && <TagModal />}
        {openRestrictionModal && organizationUser && <NeedToUpgrade />}
        {isSavedSearchModalOpen && <SavedSearchModal />}
        {isEditSavedSearchModalOpen && <EditSavedSearchModal />}
        {isSavedSearchAlertModalOpen && <SavedSearchAlertModal />}
        {isReportProblemModalOpen && <ReportProblemModal />}
        <div
          className={`page-layout__viewport primary-layout-content${
            sidebarMinimized ? ' sidebar-minimized' : ''
          } d-flex flex-column`}
        >
          {children}
          <footer className="text-center m-3">
            <small>© Plural</small>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default PrimaryLayout;

import {
  OrganizationUser,
  UserInvitation,
} from '@enview/interface/types/OrganizationUser';
import { Team, TeamMembership, TeamRole } from '@enview/interface/types/Team';
import assign from 'lodash-es/assign';
import {
  getOrganizationUser,
  getOrgIdForTeamMode,
  getTeamModeTeam,
  setTeamMode,
} from '..';
import * as Analytics from '../../analytics/TeamAnalytics';
import { TeamAPI } from '../../api';
import { ApiTag } from '../../api/base';
import instance from '../../config/axiosConfig';
import { TeamInvitation } from '../../models/Team';
import { Action, State, Thunk } from '../@types';
import {
  requestError,
  requestFailure,
  requestPending,
  requestSent,
  requestSucceeded,
  requestSuccess,
  resetRequest,
} from '../RequestDux';
import {
  ADD_ORG_USER_TEAM_MEMBERSHIP,
  CLEAR_INVITATION_LINKS,
  REMOVE_PENDING_TEAM_INVITATION,
  SET_INVITATIONS,
  SET_PENDING_TEAM_INVITES,
  SET_PENDING_TEAM_MEMBERS,
  SET_TEAM_MEMBERS,
  TeamState,
} from './types';

// REQUEST NAMES
const REQUEST_INVITE_USER = 'RequestInviteUser';
const REQUEST_INVITE_ORG_USER = 'RequestInviteOrgUser';
const REQUEST_INVITE_IP_ORG_USER = 'RequestInviteIpOrgUser';
const REQUEST_CREATE_TEAM = 'RequestCreateTeam';
const REQUEST_TEAM_MEMBERS = 'RequestTeamMembers';
const REQUEST_USER_TEAM_PENDING_INVITATIONS = 'RequestUserTeamPendingInvitations';

// REDUCER
export default function reducer(state: TeamState, action: Action): TeamState {
  switch (action.type) {
    case SET_INVITATIONS:
      return assign({}, state, {
        userInvites: action.userInvites,
      });
    case CLEAR_INVITATION_LINKS:
      return assign({}, state, {
        userInvites: undefined,
      });
    case ADD_ORG_USER_TEAM_MEMBERSHIP:
      // eslint-disable-next-line no-case-declarations
      const newState = assign({}, state);
      newState.pendingTeamInvitations = state.pendingTeamInvitations?.filter(
        (invitation) => action.teamId !== invitation.teamId,
      );
      return newState;
    case SET_PENDING_TEAM_MEMBERS:
      return assign({}, state, {
        pendingTeamMembers: action.pendingTeamMembers,
      });
    case SET_PENDING_TEAM_INVITES:
      return assign({}, state, {
        pendingTeamInvitations: action.pendingTeamInvitations,
      });
    case REMOVE_PENDING_TEAM_INVITATION:
      return assign({}, state, {
        pendingTeamInvitations: state.pendingTeamInvitations?.filter(
          (i) => i.teamId === action.teamId,
        ),
      });
    case SET_TEAM_MEMBERS:
      return assign({}, state, {
        teamMembers: action.teamMembers,
        teamOrgUsers: action.teamOrgUsers,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
export const setInvitations = (userInvites: UserInvitation[]): Action => {
  return {
    type: SET_INVITATIONS,
    userInvites,
  };
};

export const clearInvitationLinks = (): Action => {
  return {
    type: CLEAR_INVITATION_LINKS,
  };
};

const setPendingTeamMembers = (
  pendingTeamMembers: Partial<TeamMembership>[],
): Action => {
  return {
    type: SET_PENDING_TEAM_MEMBERS,
    pendingTeamMembers,
  };
};

const setPendingTeamInvitations = (
  pendingTeamInvitations: TeamInvitation[],
): Action => {
  return {
    type: SET_PENDING_TEAM_INVITES,
    pendingTeamInvitations,
  };
};

const removePendingTeamInvitations = (teamId: number): Action => {
  return {
    type: REMOVE_PENDING_TEAM_INVITATION,
    teamId,
  };
};

const setTeamMembers = (
  teamMembers: TeamMembership[],
  teamOrgUsers: OrganizationUser[],
): Action => {
  return {
    type: SET_TEAM_MEMBERS,
    teamMembers,
    teamOrgUsers,
  };
};

const addOrgUserTeamMembership = (teamId: number): Action => {
  return {
    type: ADD_ORG_USER_TEAM_MEMBERSHIP,
    teamId,
  };
};
// SELECTORS
export const inviteUserSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_INVITE_USER);
};

export const inviteUserError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_INVITE_USER);
};

export const inviteOrgUserSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_INVITE_ORG_USER);
};

export const inviteIpOrgUserSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_INVITE_IP_ORG_USER);
};

export const inviteOrgUserError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_INVITE_ORG_USER);
};

export const inviteIpOrgUserError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_INVITE_IP_ORG_USER);
};

export const createTeamSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_CREATE_TEAM);
};

export const createTeamError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_CREATE_TEAM);
};

export const requestTeamMembersPending = (state: State): boolean => {
  return requestPending(state, REQUEST_TEAM_MEMBERS);
};

export const requestTeamMembersSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_TEAM_MEMBERS);
};

export const requestTeamMembersError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_TEAM_MEMBERS);
};

export const requestUserTeamInvitesSuccess = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_USER_TEAM_PENDING_INVITATIONS);
};

export const getTeamOrgUserCount = (state: State): number | undefined => {
  return state.teams.teamOrgUsers && state.teams.teamOrgUsers.length;
};

export const getPendingTeamMembers =
  (teamId: number): Thunk =>
  async (dispatch) => {
    const response = await instance.get(`/teams/${teamId}/invitees?status=pending`);
    dispatch(setPendingTeamMembers(response.data));
  };

// THUNKS
export const inviteUser = (teamId: number, email: string, role: TeamRole): Thunk => {
  return async (dispatch) => {
    try {
      dispatch(requestSent(REQUEST_INVITE_USER));
      await instance.post(`/teams/${teamId}/invite`, { email, role });
      dispatch(requestSuccess(REQUEST_INVITE_USER));
      Analytics.trackInviteUser(true, email, role);
      dispatch(getPendingTeamMembers(teamId));
    } catch (e) {
      const error = e as Error;
      dispatch(requestFailure(REQUEST_INVITE_USER, error));
      if (error.message.includes('400')) {
        Analytics.trackInviteUser(false, email, role);
      }
    }
  };
};

export const inviteUserToOrg = (emails: string[], teamId: number): Thunk => {
  return async (dispatch) => {
    const userInvites: UserInvitation[] = [];
    dispatch(requestSent(REQUEST_INVITE_ORG_USER));
    const asyncForEach = async (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      emails: string[],
      callback: (value: string) => Promise<void>,
    ): Promise<void> => {
      for (let i = 0; i < emails.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await callback(emails[i]);
      }
    };

    try {
      const start = async (): Promise<void> => {
        await asyncForEach(emails, async (email: string): Promise<void> => {
          const payload = {
            email,
            organizationId: teamId,
          };
          await instance
            .post('/onboarding/signup-invite', payload)
            .then((res) => {
              userInvites.push(res.data);
              Analytics.trackInviteUser(true, email);
            })
            .catch((error) => {
              dispatch(requestFailure(REQUEST_INVITE_ORG_USER, error));
              Analytics.trackInviteUser(false, email);
            });
        });
      };
      await start();
      dispatch(setInvitations(userInvites));
      dispatch(requestSuccess(REQUEST_INVITE_ORG_USER));
    } catch (e) {
      const error = e as Error;
      dispatch(requestFailure(REQUEST_INVITE_ORG_USER, error));
    }
  };
};

export const inviteIpUserToOrg = (email: string): Thunk => {
  return async (dispatch) => {
    try {
      dispatch(requestSent(REQUEST_INVITE_IP_ORG_USER));
      const payload = {
        email,
      };
      await instance.post('/onboarding/signup-by-ip', payload);
      dispatch(requestSuccess(REQUEST_INVITE_IP_ORG_USER));
      Analytics.trackInviteUser(true, email);
    } catch (e) {
      const error = e as Error;
      dispatch(requestFailure(REQUEST_INVITE_IP_ORG_USER, error));
      if (error.message.includes('400')) {
        Analytics.trackInviteUser(false, email);
      }
    }
  };
};

export const clearInviteUserError = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_INVITE_USER));
  };
};

export const clearInviteOrgUserError = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_INVITE_ORG_USER));
  };
};

export const clearInviteIpOrgUserError = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_INVITE_IP_ORG_USER));
  };
};

export const getTeamMembers =
  (teamId: number, canViewTeamMfa?: boolean): Thunk =>
  async (dispatch, getState) => {
    dispatch(requestSent(REQUEST_TEAM_MEMBERS));
    try {
      const teamMembersResponse = await instance.get(`/teams/${teamId}/users`);
      let teamOrgUserResponse;
      const orgId = getOrgIdForTeamMode(getState());
      const team = getTeamModeTeam(getState());
      if (
        canViewTeamMfa &&
        orgId &&
        orgId === team?.organizationId &&
        team?.id === teamId
      ) {
        teamOrgUserResponse = await instance.get(`/organization/get-users/${orgId}`);
      }
      dispatch(requestSuccess(REQUEST_TEAM_MEMBERS));
      dispatch(setTeamMembers(teamMembersResponse.data, teamOrgUserResponse?.data));
    } catch (e) {
      const error = e as Error;
      dispatch(requestFailure(REQUEST_TEAM_MEMBERS, error));
    }
  };

export const getPendingTeamInvitesForUser = (): Thunk => async (dispatch) => {
  dispatch(requestSent(REQUEST_USER_TEAM_PENDING_INVITATIONS));
  try {
    const response = await instance.get(
      '/organization-user/team-invitations?status=pending',
    );
    dispatch(setPendingTeamInvitations(response.data));
    dispatch(requestSuccess(REQUEST_USER_TEAM_PENDING_INVITATIONS));
  } catch (e) {
    const error = e as Error;
    dispatch(requestFailure(REQUEST_USER_TEAM_PENDING_INVITATIONS, error));
  }
};

export const createTeam = (payload: Partial<Team>): Thunk => {
  return (dispatch, getState) => {
    dispatch(requestSent(REQUEST_CREATE_TEAM));
    instance
      .post('/teams', payload)
      .then((response) => {
        Analytics.trackWorkspaceCreated(payload, getState().account.organizationUser);
        dispatch(requestSuccess(REQUEST_CREATE_TEAM));
        dispatch(getOrganizationUser());
        dispatch(setTeamMode(response.data.id));
        dispatch(TeamAPI.util.invalidateTags([{ type: ApiTag.TEAM_TAG }]));
      })
      .catch((error) => {
        dispatch(requestFailure(REQUEST_CREATE_TEAM, error));
      });
  };
};

export const resetCreateTeam = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_CREATE_TEAM));
  };
};

export const acceptTeamInvite =
  (teamInvite: TeamInvitation): Thunk =>
  async (dispatch) => {
    await instance.post(`/teams/${teamInvite.teamId}/process-invite?decision=accepted`);
    // TODO: return organizationUser and then setOrganizationUser
    dispatch(TeamAPI.util.invalidateTags([{ type: ApiTag.TEAM_TAG }]));
    dispatch(addOrgUserTeamMembership(teamInvite.teamId));
    dispatch(getOrganizationUser());
  };

export const declineTeamInvite =
  (teamId: number): Thunk =>
  async (dispatch) => {
    await instance.post(`/teams/${teamId}/process-invite?decision=rejected`);
    dispatch(removePendingTeamInvitations(teamId));
  };

import { ReactElement, useRef, useState } from 'react';
import CustomDropdown from '../../Shared/DropDowns/CustomDropdown';
import ChevronIcon from '../../../components/svg/ChevronDownIcon';
import LocationIcon from '../../../components/svg/LocationIcon';
import MultiSelectForm, { MultiSelectOption } from '../../Shared/Forms/MultiSelectForm';
import { Committee } from '@enview/interface/types/Committee';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

type JursidictionCommitteeDropdownProps = {
  selectedCommittees: number[];
  setSelectedCommittees: (arg: number[]) => void;
  availableCommittees: Committee[];
};

const CommitteeDropdown = (props: JursidictionCommitteeDropdownProps): ReactElement => {
  const { selectedCommittees, setSelectedCommittees, availableCommittees } = props;

  const [presentedText, setText] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [committees, setCommittees] = useState<MultiSelectOption<number>[]>([]);

  const wrapperElemRef = useRef<HTMLDivElement>(null);
  const elementWidth = wrapperElemRef.current
    ? wrapperElemRef.current.offsetWidth
    : undefined;

  const handleTextChange = (newText: string): void => {
    let suggestions: MultiSelectOption<number>[] = [];
    if (newText.length > 0) {
      suggestions = availableCommittees
        .filter((v: Committee) => v.name.toLowerCase().includes(newText.toLowerCase()))
        .map((v: Committee) => ({
          value: v.id,
          label: v.name,
        }));
      setShowDropdown(true);
    }

    if (newText.length === 0) {
      suggestions = availableCommittees.map((v) => {
        return { value: v.id, label: v.name };
      });
    }

    setCommittees(suggestions);
    setText(newText);
  };

  const toggleButton = (selectedCount: number): ReactElement => {
    return (
      <button
        className="d-flex btn btn-text ml-auto align-items-center"
        id="legislature-dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        type="button"
      >
        {selectedCount !== undefined && selectedCount > 0 && (
          <div className="dropdown-count">{selectedCount}</div>
        )}
        <ChevronIcon />
      </button>
    );
  };

  const handleCommitteeChange = (updatedCommittees: number[]): void => {
    if (isEqual(updatedCommittees, selectedCommittees)) {
      return;
    }

    setSelectedCommittees(updatedCommittees);
    handleTextChange('');
  };

  const buildCommitteeOptions = (): MultiSelectOption<number>[] => {
    const selectedOptions: MultiSelectOption<number>[] = [];
    const unselectionOptions: MultiSelectOption<number>[] = [];

    const opts =
      committees.length === 0 && presentedText.length === 0
        ? availableCommittees.map((c) => ({ value: c.id, label: c.name }))
        : committees;

    opts.forEach((committee) => {
      const include = selectedCommittees.find((i) => i === committee.value);

      if (include) {
        selectedOptions.push(committee);
      } else {
        unselectionOptions.push(committee);
      }
    });

    return selectedOptions.concat(unselectionOptions);
  };
  const { t } = useTranslation();

  return (
    <div className="jurisdiction-dropdown" ref={wrapperElemRef}>
      <div className="row flex-nowrap col-4">
        <div className="location-icon">
          <LocationIcon />
        </div>

        <input
          onChange={(event) => handleTextChange(event.target.value)}
          placeholder={t('hearings.committees.placeholder', {
            count: selectedCommittees.length,
          })}
          type="text"
          value={presentedText}
        />

        <CustomDropdown
          alignRight
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
          target={toggleButton(selectedCommittees.length)}
          width={elementWidth}
        >
          <div className="jurisdiction-dropdown-menu">
            <MultiSelectForm
              currentSelection={selectedCommittees}
              options={buildCommitteeOptions()}
              showClearAll
              showSelectAll
              showSelectButtonsBottom
              singleColumn
              updateSelection={handleCommitteeChange}
            />
          </div>{' '}
        </CustomDropdown>
      </div>
    </div>
  );
};

export default CommitteeDropdown;

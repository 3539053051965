import { JurisdictionDetailLink } from '@enview/interface/types/JurisdictionDetailLink';
import { JurisdictionReferenceInfo } from '@enview/interface/types/JurisdictionReferenceInfo';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import trackResourceLinkClick from '../../analytics/JurisdictionAnalytics';
import { State } from '../../dux/@types';
import './Jurisdictions.scss';

type JurisdictionPopupProps = {
  popupRef: React.RefObject<HTMLDivElement>;
  content: JurisdictionReferenceInfo | undefined;
  isFetching: boolean;
  isError: boolean;
  billCount?: number;
  hasAccess: boolean;
};

enum LinkCategory {
  Legislative = 'Legislative',
  Regulatory = 'Regulatory',
}

const renderLinks = (
  jurisdictionAbbr: string,
  links: JurisdictionDetailLink[],
  orgUser?: OrganizationUser,
): ReactElement => {
  return (
    <>
      {links.map((link) => (
        <div key={link.id}>
          <a
            href={link.url}
            key={link.id}
            onClick={() => {
              trackResourceLinkClick(
                jurisdictionAbbr,
                link.category,
                link.url,
                'Jurisidiction Explorer Popup',
                orgUser,
              );
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {link.title}
          </a>
        </div>
      ))}
    </>
  );
};

const JurisdictionPopup = (props: JurisdictionPopupProps): ReactElement => {
  const { popupRef, content, billCount, isFetching, isError, hasAccess } = props;
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const legLinks = content?.links.filter(
    (l) => l.category === LinkCategory.Legislative,
  );
  const regLinks = content?.links.filter((l) => l.category === LinkCategory.Regulatory);
  const initialStyle: React.CSSProperties = { display: 'none', position: 'absolute' };
  if (!hasAccess) {
    return (
      <div id="explorer-popup-container" ref={popupRef} style={initialStyle}>
        Contact support for access to legislative and regulatory data in this
        jurisdiction!
      </div>
    );
  }
  if (isFetching) {
    return (
      <div id="explorer-popup-container" ref={popupRef} style={initialStyle}>
        Loading...
      </div>
    );
  }
  if (content) {
    return (
      <div id="explorer-popup-container" ref={popupRef} style={initialStyle}>
        <h3>{content.name}</h3>
        <div>
          Sessions Available:{' '}
          {content.sessionsCount > 0 ? (
            <Link to={`/jurisdictions/${content.jurisdictionAbbr}`}>
              {content.sessionsCount}
            </Link>
          ) : (
            <span>0</span>
          )}
        </div>
        {billCount ? (
          <div>
            Bills Tracked:
            <Link to={`/jurisdictions/${content.jurisdictionAbbr}/bills`}>
              {' '}
              {billCount}{' '}
            </Link>
          </div>
        ) : (
          <></>
        )}
        <div>
          {/* eslint-disable-next-line */}
          Legislators Available:{' '}
          <Link to={`/jurisdictions/${content.jurisdictionAbbr}/legislators`}>
            {content.legislatorCount}
          </Link>
        </div>
        <div>
          Committees Available:{' '}
          {content.committeeCount > 0 ? (
            <Link to={`/jurisdictions/${content.jurisdictionAbbr}/committees`}>
              {content.committeeCount}
            </Link>
          ) : (
            <span>0</span>
          )}
        </div>
        {/* <div>
          Hearings Available:{' '}
          {content.hearingsCount > 0 ? (
            <Link to={`/jurisdictions/${content.jurisdictionAbbr}/hearings`}>
              {content.hearingsCount}
            </Link>
          ) : (
            <span>0</span>
          )}
        </div> */}
        {legLinks && legLinks.length > 0 ? (
          <>
            <h4>Legislative</h4>
            {renderLinks(content.jurisdictionAbbr, legLinks, organizationUser)}
          </>
        ) : (
          <></>
        )}
        {regLinks && regLinks.length > 0 ? (
          <>
            <h4>Regulatory</h4>
            {renderLinks(content.jurisdictionAbbr, regLinks, organizationUser)}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
  if (isError) {
    return (
      <div id="explorer-popup-container" ref={popupRef} style={initialStyle}>
        Error loading reference content
      </div>
    );
  }
  return (
    <div id="explorer-popup-container" ref={popupRef} style={initialStyle}>
      Failed to load reference content
    </div>
  );
};

export default JurisdictionPopup;

/* eslint-disable react/jsx-max-depth */
import { Bill } from '@enview/interface/types/bills/Bill';
import { SavedSearch } from '@enview/interface/types/BillSearch';
import { Tag } from '@enview/interface/types/tags/Tag';
import { TeamRole } from '@enview/interface/types/Team';
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { BillAPI, TagAPI, TeamAPI } from '../../api';
import { SavedSearchAlertBell } from '../../components/savedSearches/SavedSearchAlertBell';
import ArrowDown from '../../components/svg/ArrowDown';
import BillIcon from '../../components/svg/BillIcon';
import MagnifyingGlass from '../../components/svg/MagnifyingGlass';
import Plus from '../../components/svg/Plus';
import TagIcon from '../../components/svg/TagIcon';
import {
  getTeamMode,
  getTeamModeTeam,
  openExportBillsModal,
  openTagModal,
  resetCreateTeam,
  setTeamMode,
} from '../../dux';
import { State } from '../../dux/@types';
import featureFlags, { FrontendFeature } from '../../featureFlags';
import { getBillAlias, getBillTitle } from '../../helpers/BillHelper';
import { mobileMediaQuery } from '../../helpers/mediaQueries';
import { sortTags } from '../../helpers/TagHelper';
import { TeamBillGroup } from '../../models/BillGroup';
import { SortBy, SortOrder } from '../../models/SortOrder';
import { sortBillsBySortLabel } from '../../utils';
import CustomToggleSelectDropDown from '../Shared/DropDowns/CustomToggleSelectDropDown';
import SingleSelectDropDown, {
  OptionType,
} from '../Shared/DropDowns/SingleSelectDropDown';
import ModalCard from '../Shared/Modals/ModalCard';
import CreateTeam from '../TeamView/CreateEditTeam';
import './PrimarySidebar.scss';
import './PrimaryView.scss';

type PrimarySidebarProps = {
  isMinimized: boolean;
  minimize: () => void;
};

const BILL_DETAILS_PATH = '/legislative-tracking/bill/details/';
const TAG_PATH = '/legislative-tracking/tagged-bills/';
const SAVED_SEARCH_PATH = '/legislative-tracking/saved-search/';
const BILLS_HEADER_TEXT = 'BILLS';
type BillLinksProps = {
  bills: Bill[];
  onClick: () => void;
  isCollapsible: boolean;
};

const BILL_SORT_ORDER = new SortOrder(SortBy.ALPHANUMERICAL, false);

const BillLinks = (props: BillLinksProps): ReactElement => {
  const { bills, onClick, isCollapsible } = props;
  const teamModeId = useSelector(getTeamMode);
  const sortedBills: Bill[] = sortBillsBySortLabel(bills, BILL_SORT_ORDER);
  const body =
    sortedBills.length === 0 ? (
      <div className="tracked-bill-empty">No tracked bills</div>
    ) : (
      sortedBills.map((bill) => {
        const { session, number, userAliases } = bill;
        const billAlias = getBillAlias(userAliases, teamModeId);
        const title = getBillTitle(session, number, billAlias);
        const billName = billAlias
          ? `${bill.number} (${billAlias.alias})`
          : bill.number;
        const tooltip = `${title} - ${bill.name}${
          billAlias ? ` (${billAlias.alias})` : ''
        }`;
        const { state } = bill.session;
        return (
          <div key={bill.id}>
            <Link
              className="d-flex bill-link"
              onClick={onClick}
              to={`${BILL_DETAILS_PATH}${bill.id}`}
            >
              <span className="bill-link body-link" id={bill.id}>
                <span className="bill-link-state">
                  {state.abbreviation === 'usa'
                    ? 'FED'
                    : state.abbreviation.toUpperCase()}
                </span>
                <span>&nbsp;</span>
                <span className="bill-link-name">{billName}</span>
                <br />
              </span>
              <ReactTooltip
                anchorId={bill.id}
                className="bill-link-tooltip tooltip-content"
                place="right"
              >
                {tooltip}
              </ReactTooltip>
            </Link>
          </div>
        );
      })
    );

  if (isCollapsible) {
    return (
      <Accordion defaultActiveKey="0">
        <Card>
          <div className="section">
            <Accordion.Toggle
              as={Card.Header}
              className="d-flex flex-row header"
              eventKey="0"
            >
              <BillIcon />
              <div className="title">
                {BILLS_HEADER_TEXT} ({bills.length})
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{body}</Card.Body>
            </Accordion.Collapse>
          </div>
        </Card>
      </Accordion>
    );
  }
  return (
    <div className="section">
      <div className="d-flex flex-row header">
        <BillIcon />
        <div className="title">
          {BILLS_HEADER_TEXT} ({bills.length})
        </div>
      </div>
      {body}
    </div>
  );
};

const NO_ORG_TEXT = 'Personal';
const TEAM_TAGS_HEADER_TEXT = 'TAGS';
const SAVED_SEARCHES_HEADER_TEXT = 'SEARCHES';
const TAG_SORT_ORDER = new SortOrder(SortBy.ALPHABETICAL, false);

type TagLinksProps = {
  tags: Tag[];
  onClick: () => void;
  isCollapsible: boolean;
};

const TagLinks = (props: TagLinksProps): ReactElement => {
  const { tags, onClick, isCollapsible } = props;

  const dispatch = useDispatch();
  const sortedTags = sortTags(tags, TAG_SORT_ORDER);

  const body = sortedTags.map(
    (tag): ReactElement => (
      <Link
        className="body-link tag-link"
        key={tag.id}
        onClick={onClick}
        to={`${TAG_PATH}${tag.id}`}
      >
        <span className="tag-link-text">{tag.name.replace('#', '')}</span>
        <br />
      </Link>
    ),
  );
  if (isCollapsible) {
    return (
      <Accordion defaultActiveKey="0">
        <Card>
          <div className="section">
            <Accordion.Toggle
              as={Card.Header}
              className="d-flex flex-row header"
              eventKey="0"
            >
              <TagIcon />
              <div className="title">
                {TEAM_TAGS_HEADER_TEXT} ({tags?.length || 0})
              </div>
              <button
                className="btn-text icon"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(openTagModal());
                }}
                style={{ padding: 0 }}
                type="button"
              >
                <Plus />
              </button>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{body}</Card.Body>
            </Accordion.Collapse>
          </div>
        </Card>
      </Accordion>
    );
  }
  return (
    <div className="section">
      <div className="d-flex flex-row header">
        <TagIcon />
        <div className="title">
          {TEAM_TAGS_HEADER_TEXT} ({tags?.length || 0})
        </div>
        <button
          className="btn-text icon"
          onClick={() => dispatch(openTagModal())}
          style={{ padding: 0 }}
          type="button"
        >
          <Plus />
        </button>
      </div>
      {body}
    </div>
  );
};

type SavedSearchLinksProps = {
  searches: SavedSearch[];
  onClick: () => void;
  isCollapsible: boolean;
};

const SearchLinks = (props: SavedSearchLinksProps): ReactElement => {
  const { searches, onClick, isCollapsible } = props;
  const teamModeId = useSelector(getTeamMode);
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const alertsAvailable = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.SavedSearchAlerts,
    organizationUser,
  );

  const body = searches.map(
    (search): ReactElement => (
      <div className="d-flex" key={search.id}>
        {organizationUser && alertsAvailable && (
          <SavedSearchAlertBell
            className="saved-search-bell-shared"
            orgUser={organizationUser}
            savedSearch={search}
            showModalOnAdd
            teamId={teamModeId}
          />
        )}
        {/* nosemgrep: semgrep.rules.auto.react-href-var */}
        <Link
          className="d-flex body-link bill-link"
          id={search.id}
          onClick={onClick}
          to={`${SAVED_SEARCH_PATH}${search.id}`}
        >
          <span className="bill-link" id={search.id}>
            <span className="bill-link-name">{search.name}</span>
          </span>
        </Link>
        <ReactTooltip
          anchorId={search.id}
          className="bill-link-tooltip saved-search-tooltip tooltip-content"
          place="right"
        >
          {search.name}
        </ReactTooltip>
      </div>
    ),
  );

  if (isCollapsible) {
    return (
      <Accordion defaultActiveKey="0">
        <Card>
          <div className="section">
            <Accordion.Toggle
              as={Card.Header}
              className="d-flex flex-row header"
              eventKey="0"
            >
              <MagnifyingGlass height="16" width="16" />
              <div className="title">
                {SAVED_SEARCHES_HEADER_TEXT} ({searches?.length || 0})
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{body}</Card.Body>
            </Accordion.Collapse>
          </div>
        </Card>
      </Accordion>
    );
  }
  return (
    <div className="section">
      <div className="d-flex flex-row header">
        <MagnifyingGlass height="16" width="16" />
        <div className="title">
          {SAVED_SEARCHES_HEADER_TEXT} ({searches?.length || 0})
        </div>
      </div>
      {body}
    </div>
  );
};

// Button padding - 1
const BUTTON_DROPDOWN_OFFSET = 19;

const PrimarySidebar = (props: PrimarySidebarProps): ReactElement => {
  const { minimize, isMinimized } = props;
  const dispatch = useDispatch();
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const isCollapsible = featureFlags.isFeatureEnabledForUser(
    FrontendFeature.CollapsibleSideMenu,
    organizationUser,
  );

  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);

  const team = useSelector(getTeamModeTeam);
  const organization =
    organizationUser && team
      ? organizationUser.organizations.find((org) => org.id === team.organizationId)
      : undefined;

  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const dropdownButtonRef = useRef<HTMLDivElement>(null);

  const [dropdownPosition, setDropdownPosition] = useState<number>(-1);
  const handleScroll = (): void => {
    if (dropdownButtonRef.current) {
      setDropdownPosition(
        dropdownButtonRef.current.getBoundingClientRect().bottom -
          BUTTON_DROPDOWN_OFFSET,
      );
    }
  };

  const { data: trackedBills } = BillAPI.endpoints.getTrackedBills.useQuery(undefined);
  const { data: allTags } = TagAPI.endpoints.getTags.useQuery(undefined);
  const { data: savedSearches } = TeamAPI.endpoints.getSavedSearches.useQuery(
    useSelector(getTeamMode),
  );

  const sortedSearches = useMemo(
    () =>
      savedSearches && [...savedSearches].sort((a, b) => a.name.localeCompare(b.name)),
    [savedSearches],
  );

  const tags = useMemo(
    () => allTags?.filter((tag) => tag.teamId === team?.id),
    [team, allTags],
  );

  const teams = useMemo(
    () => (teamMemberships || []).map((tm) => tm.team),
    [teamMemberships],
  );

  const trackedBillsByTeam = useMemo<{ [teamId: number]: Bill[] }>(() => {
    if (!trackedBills) return {};
    const trackedBillsData: { [teamId: number]: Bill[] } = {};
    trackedBills.forEach((bill: Bill) => {
      if (!bill.teams) return;
      bill.teams.forEach((t) => {
        if (!trackedBillsData[t.id]) {
          trackedBillsData[t.id] = [bill];
        } else {
          trackedBillsData[t.id].push(bill);
        }
      });
    });
    return trackedBillsData;
  }, [trackedBills]);

  const minimizeIfMobile = useCallback(() => {
    if (mobileMediaQuery.matches) {
      minimize();
    }
  }, [minimize]);

  Modal.setAppElement('#root');

  const orgOptions = useMemo(
    () =>
      [
        {
          label: NO_ORG_TEXT,
          value: -1,
          onSelect: () => {
            const selectedTeam = teams.find((t) => !t.organizationId);
            if (!selectedTeam) return;
            dispatch(setTeamMode(selectedTeam.id));
          },
        },
      ].concat(
        organizationUser
          ? organizationUser.organizations
              .filter((org) => org.hasReadOnlyAccess === false)
              .sort((org1, org2) => org1.name.localeCompare(org2.name))
              .map((org) => ({
                label: org.name,
                value: org.id,
                onSelect: () => {
                  const selectedTeam = teams.find((t) => t.organizationId === org.id);
                  if (!selectedTeam) return;
                  dispatch(setTeamMode(selectedTeam.id));
                },
              }))
          : [],
      ),
    [organizationUser, teams],
  );
  const canCreateTeams = useMemo(() => {
    if (!organizationUser || !organization) return false;
    if (
      organizationUser.roles.find(
        (role) => role.organizationId === organization.id && role.name === 'org-admin',
      )
    )
      return true;
    return !!(teamMemberships || []).find(
      (tm) => tm.role !== TeamRole.MEMBER && tm.team.organizationId === organization.id,
    );
  }, [organization, organizationUser, teamMemberships]);

  const teamsOptions = useMemo(() => {
    const options: OptionType<number>[] = teams
      .filter(
        (t) =>
          (!organization && !t.organizationId) || t.organizationId === organization?.id,
      )
      .sort((team1, team2) => team1.name.localeCompare(team2.name))
      .map((t) => ({
        label: t.name.toUpperCase(),
        value: t.id,
      }));
    if (canCreateTeams) {
      options.push({
        label: 'NEW WORKSPACE',
        value: 0,
        icon: <Plus />,
      });
    }
    return options;
  }, [canCreateTeams, teams, organization]);

  if (!organizationUser) {
    return <div />;
  }
  const bills = (team ? trackedBillsByTeam[team.id] : []) || [];
  const orgToggle = (
    <div className="d-flex flex-row justify-content-between align-items-center toggle-container">
      <div className="mr-2">{organization?.name || NO_ORG_TEXT}</div>
      <ArrowDown />
    </div>
  );

  const exportBillList = (): void => {
    const filters = teams.map((t) => new TeamBillGroup(t.id));
    dispatch(openExportBillsModal(filters));
  };

  const exportBill = (
    <div className="bill-track-link d-flex">
      <button
        className="btn btn-sm btn-secondary ml-4"
        onClick={exportBillList}
        type="button"
      >
        Export
      </button>
    </div>
  );

  return (
    <div
      className={`primary-sidebar-layout${isMinimized ? ' sidebar-minimized' : ''}`}
      onScroll={() => handleScroll()}
    >
      <nav id="primary-sidebar">
        <div className="org-label">
          <CustomToggleSelectDropDown options={orgOptions} toggle={orgToggle} />
        </div>
        <div className="team-mode-switcher" ref={dropdownButtonRef}>
          <SingleSelectDropDown
            defaultLabel="Current Team"
            dropdownPosition={dropdownPosition}
            onSelect={(teamOption) => {
              if (teamOption.value === 0) {
                setShowCreateTeamModal(true);
                return;
              }
              dispatch(setTeamMode(teamOption.value));
            }}
            options={teamsOptions}
            selectedValue={team?.id || -1}
          />
        </div>
        <div>
          <BillLinks
            bills={bills}
            isCollapsible={isCollapsible}
            onClick={minimizeIfMobile}
          />
          <div className="divider-line" />
          <TagLinks
            isCollapsible={isCollapsible}
            onClick={minimizeIfMobile}
            tags={tags || []}
          />
          <div className="divider-line" />
          <SearchLinks
            isCollapsible={isCollapsible}
            onClick={minimizeIfMobile}
            searches={sortedSearches || []}
          />
          <div className="divider-line" />
        </div>
        {exportBill}
      </nav>
      <ModalCard
        closeModal={() => {
          dispatch(resetCreateTeam());
          setShowCreateTeamModal(false);
        }}
        content={
          <CreateTeam
            closeModal={() => {
              dispatch(resetCreateTeam());
              setShowCreateTeamModal(false);
            }}
            isTeamCreation
          />
        }
        show={showCreateTeamModal}
        title="Create Workspace"
      />
    </div>
  );
};

export default PrimarySidebar;

import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';

type SwitchIconProps = {
  id?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onDescription?: string;
  offDescription?: string;
  disabled?: boolean;
};

const SwitchIcon = (props: SwitchIconProps): ReactElement => {
  const { id, value, onChange, onDescription, offDescription, disabled } = props;

  const onClick = (): void => {
    if (!disabled) onChange(!value);
  };

  return (
    <div aria-disabled={disabled} className="switch-icon d-flex flex-row">
      <FontAwesomeIcon
        color={value ? '#1AA08F' : '#979797'}
        icon={faToggleOn}
        id={id}
        onClick={onClick}
        rotation={value ? undefined : 180}
        size="2x"
      />
      {value && onDescription && (
        <div className="switch-description">{onDescription}</div>
      )}
      {!value && offDescription && (
        <div className="switch-description">{offDescription}</div>
      )}
    </div>
  );
};

export default SwitchIcon;

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlacesType, ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import { trackTooltipGate } from '../../../analytics/AppAnalytics';
import { State } from '../../../dux/@types';
import './TooltipGate.scss';

export enum GateTooltipFeature {
  BillAnnotating = 'Bill Annotating',
  BillTracking = 'Bill Tracking',
  BillSharing = 'Bill Sharing',
  BillViewDropdown = 'Bill View Dropdown',
  BillTagging = 'Bill Tagging',
  BillShortname = 'Bill Shortname',
  BillSummary = 'AI Bill Summary',
  PublishTag = 'Publish Tag',
  CommentForm = 'Comment Form',
  SidebarSection = 'Sidebar Section',
  Export = 'Export',
  Committees = 'Committees',
  SavedSearches = 'Saved Searches',
  TrackedBillsFilter = 'Tracked Bills Filter',
  MomentumFilter = 'Momentum Filter',
  CommitteeMentions = 'Committee Mentions',
  Intelligence = 'Intelligence',
  AllTags = 'All Tags',
  OrganizationSwitcher = 'Organization Switcher',
  TeamSwitcher = 'Team Switcher',
  ShareBill = 'Share Bill',
  BillAlias = 'Bill Alias',
  ViewHearings = 'Activity Hearings Filter',
}

interface GateTooltipProps extends ITooltip {
  accountType: 'free' | 'pro';
  anchorId: string;
  place: PlacesType;
  featureName?: GateTooltipFeature | string;
  customContent?: ReactElement | string;
  className?: string;
}

const GateTooltip = (props: GateTooltipProps): ReactElement => {
  const {
    accountType,
    anchorId,
    place,
    featureName,
    customContent,
    className,
    ...tooltipConfig
  } = props;
  const orgUser = useSelector((state: State) => state.account.organizationUser);

  const trackTooltipGateAnalytics = (): void => {
    trackTooltipGate(featureName ?? anchorId, orgUser);
  };

  const ctaFreeContent = (
    <>
      This feature is only available with an account.&nbsp;
      <Link to="/create-account">Create a free account</Link>.
    </>
  );
  const ctaProContent = (
    <>
      This feature is only available with a paid account.&nbsp;
      <a href="https://pluralpolicy.com/pricing" target="_blank">
        Find out how you can upgrade
      </a>
      .
    </>
  );

  const getTooltipText = (): ReactElement | string => {
    if (customContent) {
      return customContent;
    }
    return accountType === 'free' ? ctaFreeContent : ctaProContent;
  };

  return (
    <ReactTooltip
      afterShow={trackTooltipGateAnalytics}
      anchorSelect={`#${anchorId}`}
      className={`tooltip-gate ${className}`}
      clickable
      openOnClick={true}
      place={place}
      {...tooltipConfig}
    >
      <div className="tooltip-gate-content">
        <FontAwesomeIcon color="#FF8427" icon={faLock} size="lg" />
        <p className="tooltip-gate-text">{getTooltipText()}</p>
      </div>
    </ReactTooltip>
  );
};

export default GateTooltip;

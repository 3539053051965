import { Jurisdiction } from './Jurisdiction';

/** Represents a single session at a given legislative body. Bills are attached
 * to a single session, so LegislativeSession by consequence is an important
 * organizing identifier in our data model
 */
export type LegislativeSession = {
  /** Identifying number of this legislative session. Stored as an
   * auto-incrementing integer in the Enview postgres database */
  id: number;
  /** Identifying number of the associated Jurisdiction. This is a Foreign Key
   * to the `jurisdictions` table in the Enview postgres database */
  stateId: number;
  /** Identifying number of the databse record denoting the legislative term.
   * This is a foreign key to the `legislative_terms` table in the Enview
   * postgres database */
  termId: number;
  /** The type of this LegislativeSession, for sessions that have a designated
   * type in the source data. Example values in practice are "primary" and
   * "special" */
  type: string;
  /** The name of this LegislativeSession in the Enview database */
  name: string;
  /** Date at which this LegislativeSession officially started, by the standards
   * of the legislative body */
  startedAt: string;
  /** Date at which this LegislativeSession officially ended or adjourned, by
   * the standards of the legislative body */
  endedAt: string;
  /** The user-friendly name of this LegislativeSession that we want to display
   * in the Enview UI */
  displayName: string;
  /** Timestamp at which this record was created. Automatically added by the
   * knex `timestamps()` function */
  createdAt: string;
  /** Timestamp at which this record was last updated. Automatically added by
   * the knex `timestamps()` function */
  updatedAt: string;
  /** Boolean value designating whether this session will be available to be
   * searched in the Enview UI */
  defaultSession: boolean;
  /** Boolean value designating whether this session will be searched *by
   * default* (checked) in the Enview UI */
  isCurrent: boolean;
};

/** A Representation of a LegislativeSession including an object representation
 * of the associated Jurisdiction */
export type LegislativeSessionWithState = LegislativeSession & {
  state: Jurisdiction;
};

/** Values that designate a type of LegislativeChamber and representations of
 * queries regarding LegislativeChamber type */
export enum ChamberType {
  /** Designates the "Upper" legislative chamber, most often a "Senate" */
  UPPER = 'upper',
  /** Designates the "Lower" legislative chamber, most often a "House of
   * Representatives" */
  LOWER = 'lower',
  /** Designates an object or action that is shared by multiple chambers, e.g. a
   * Joint Committee */
  JOINT = 'joint',
  /** Designates a single legislative body */
  LEGISLATURE = 'legislature',
  /** Designates that the type of legislative chamber is missing or unknown */
  UNKNOWN = '',
  /** Designates that the given query or action should apply to any legislative
   * chamber, including the upper and/or lower chamber */
  BOTH = 'both',
}

/** Represents a legislative chamber of a particular Jurisdiction. Corresponds
 * to the `legislative_chamber` table in the Enview postgres database */
export interface LegislativeChamber {
  /** The type of legislative chamber represented, usually 'upper' or 'lower' */
  type: ChamberType;
  /** The name of this legislative chamber */
  name: string;
  /** The jurisdiction associated with this legislative chamber */
  state: Jurisdiction;
}

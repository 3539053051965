import LegislativeEventsSearchParams from '@enview/interface/events/LegislativeEventsSearchParams';
import { SearchResult } from '@enview/interface/types/BillSearch';
import { JurisdictionReferenceInfo } from '@enview/interface/types/JurisdictionReferenceInfo';
import { Hearing } from '@enview/interface/types/bills/Bill';
import base from './base';

export const CURRENT_SESSION_FILTER = 'Current Sessions';

export type JurisdictionSessionResult = {
  jurisdiction: string;
  displayName: string;
  sessionId: number;
  billCount?: number;
};

const jurisdictionAPI = base.injectEndpoints({
  endpoints: (build) => ({
    getSessions: build.query<JurisdictionSessionResult[], string[]>({
      query: (params) => {
        const jurisdictionParams = params.map(
          (jurisdiction) => `state=${jurisdiction.toLowerCase()}`,
        );
        return {
          url: `/legislative-sessions?${jurisdictionParams.join('&')}&unique=true`,
          method: 'GET',
        };
      },
      transformResponse: (
        response: {
          name: string;
          display_name: string;
          id: number;
          bill_count: number;
        }[],
      ) =>
        response.map((data) => ({
          jurisdiction: data.name,
          displayName: data.display_name,
          sessionId: data.id,
          billCount: data.bill_count,
        })),
    }),
    getReferenceInfo: build.query<JurisdictionReferenceInfo, string>({
      query: (jurisAbbr) => {
        const juris = jurisAbbr.toLowerCase();
        return {
          url: `/jurisdictions/${juris}/reference-info`,
          method: 'GET',
        };
      },
    }),
    getEvents: build.query<
      SearchResult<Hearing>,
      { jurisAbbr: string; params: LegislativeEventsSearchParams }
    >({
      query: (search) => {
        const jurisAbbr = search.jurisAbbr.toLowerCase();
        return {
          url: `v2/jurisdictions/${jurisAbbr}/events?${convertParamsToQuery(
            search.params,
          )}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

const convertParamsToQuery = (params: LegislativeEventsSearchParams): string => {
  const paramsToConvert = { ...params, committeeIds: null };
  const urlParams = Object.entries(paramsToConvert)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      const isDate = value instanceof Date;
      const formattedValue = isDate ? value.toISOString() : String(value);
      return `${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`;
    });

  const committeeIds = params.committeeIds || [];
  if (committeeIds.length >= 1) {
    urlParams.push(...committeeIds?.map((id) => `committeeIds[]=${id}`));
  }

  return urlParams.join('&');
};

export default jurisdictionAPI;

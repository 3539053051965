import { connect } from 'react-redux';
import PublicLayout from '../components/PublicLayout';
import { checkPermission } from '../dux';

const mapStateToProps = (state) => {
  return {
    openRestrictionModal: state.modals.modalOpenLoginRestriction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckPermission: (permission) => {
      return dispatch(checkPermission(permission));
    },
  };
};

const PublicLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicLayout);

export default PublicLayoutContainer;

import React, { ReactElement, ReactNode } from 'react';
import {
  ArrowContainer,
  Popover,
  PopoverAlign,
  PopoverPosition,
} from 'react-tiny-popover';
import CloseIcon from '../../../components/svg/CloseIcon';
import './Popover.scss';

type PopoverConfirmProps = {
  show: boolean;
  target?: ReactElement;
  content: ReactNode;
  toggleCallback: (evt: React.SyntheticEvent) => void;
  showArrowContainer: boolean;
  disableReposition: boolean;
  align?: PopoverAlign;
  label?: string;
  labelIcon?: ReactElement;
  arrowColor?: string;
  closePopup?: () => void;
  onClickOutside?: () => void;
  style?: Partial<CSSStyleDeclaration>;
  positions?: PopoverPosition[];
  className?: string;
};

const stopActionPropagation = (evt: React.SyntheticEvent) => {
  evt.stopPropagation();
};

const contentRenderer = (props: PopoverConfirmProps) => {
  const { closePopup, content, label, labelIcon } = props;
  return (
    <div
      className="popover-content"
      onClick={stopActionPropagation}
      onKeyDown={stopActionPropagation}
    >
      {closePopup && (
        <div className="d-flex flex-row">
          <CloseIcon className="ml-auto" onClick={closePopup} />
        </div>
      )}
      {label && (
        <div className="popover-confirm-label">
          {labelIcon && <div style={{ marginRight: '8px' }}>{labelIcon}</div>}
          <span>{label}</span>
        </div>
      )}
      <div className="popover-confirm-content">{content}</div>
    </div>
  );
};

const arrowRenderer = (popoverInfo: any, props: PopoverConfirmProps) => {
  const { arrowColor } = props;
  const { position, childRect, popoverRect } = popoverInfo;
  return (
    <ArrowContainer
      arrowColor={arrowColor || ''}
      arrowSize={10}
      childRect={childRect}
      popoverRect={popoverRect}
      position={position}
    >
      {contentRenderer(props)}
    </ArrowContainer>
  );
};

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
  onClick(evt: React.SyntheticEvent): void;
}
const PopoverRefWrapper = React.forwardRef<HTMLDivElement, CustomComponentProps>(
  (props, ref) => (
    <div
      onClick={(evt: React.SyntheticEvent) => props.onClick(evt)}
      onKeyDown={(evt: React.SyntheticEvent) => evt.stopPropagation()}
      ref={ref}
    >
      {props.children}
    </div>
  ),
);

const ConfirmActionPopover = (props: PopoverConfirmProps) => {
  const {
    label,
    show,
    align,
    toggleCallback,
    target,
    showArrowContainer,
    style,
    disableReposition,
    closePopup,
    onClickOutside,
    positions,
    className,
  } = props;

  const toggleCallbackWrapper = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    toggleCallback(event);
  };

  return (
    <Popover
      align={align || 'center'}
      containerClassName={`popover-container ${className || ''}`}
      containerStyle={style}
      content={
        showArrowContainer
          ? (info) => arrowRenderer(info, props)
          : contentRenderer(props)
      }
      isOpen={show}
      onClickOutside={onClickOutside || closePopup}
      positions={positions || ['bottom', 'left', 'right', 'top']}
      reposition={!disableReposition}
    >
      {target ? (
        <PopoverRefWrapper onClick={toggleCallbackWrapper}>{target}</PopoverRefWrapper>
      ) : (
        <button
          className="form-popover-target"
          onClick={toggleCallbackWrapper}
          onKeyDown={(evt: React.SyntheticEvent) => evt.stopPropagation()}
          type="button"
        >
          {label}
        </button>
      )}
    </Popover>
  );
};

ConfirmActionPopover.defaultProps = {
  disableReposition: false,
  showArrowContainer: false,
};

export default ConfirmActionPopover;

import { ReactElement, useEffect, useMemo, useState } from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useTranslation } from 'react-i18next';
import { DecodedValueMap, QueryParamConfigMap, SetQuery } from 'use-query-params';
import { CommitteeAPI } from '../../../api';
import DayPickerInputDropdown from '../../Shared/Selectors/DayPickerInputDropdown';
import CommitteeDropdown from './CommitteeDropdown';

type JurisdictionHearingsFiltersProps = {
  query: DecodedValueMap<QueryParamConfigMap>;
  resetScroll: () => void;
  setQuery: SetQuery<QueryParamConfigMap>;
  jurisAbbr: string;
};

const JurisdictionHearingsFilters = (
  props: JurisdictionHearingsFiltersProps,
): ReactElement => {
  const { query, resetScroll, setQuery, jurisAbbr } = props;
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate, setToDate] = useState<Date | undefined>();
  const [showFromDatePicker, setShowFromDatePicker] = useState(false);
  const [showToDatePicker, setShowToDatePicker] = useState(false);
  const [selectedCommittees, setSelectedCommittees] = useState<number[]>([]);
  const { t } = useTranslation();

  const { data: committees, isFetching: isFetchingCommittees } =
    CommitteeAPI.endpoints.getCommitteesByJurisdiction.useQuery(jurisAbbr);

  const uniqueCommittees = useMemo(() => {
    if (!committees) return [];

    const seenNames = new Set();

    return committees.filter((v) => {
      if (seenNames.has(v.name)) {
        return false;
      } else {
        seenNames.add(v.name);
        return true;
      }
    });
  }, [committees]);

  useEffect(() => {
    if (query.startDateRange) {
      setFromDate(query.startDateRange);
    }
    if (query.endDateRange) {
      setToDate(query.endDateRange);
    }
    if (query.committeeIds) {
      setSelectedCommittees(
        query.committeeIds.filter((s: number | null) => {
          if (!s) return;
          return uniqueCommittees.some((v) => v.id === s);
        }),
      );
    }
  }, [query]);

  // Allows us include multiple committees with same name in our search without duplicates in search options
  const committeeIds = useMemo(() => {
    const namesToMatch = selectedCommittees.map((id) => {
      const cmte = committees?.find((v) => v.id === id);
      return cmte ? cmte.name.toLowerCase() : null;
    });

    const ids = committees
      ?.filter((v) => namesToMatch.includes(v.name.toLowerCase()))
      .map((v) => v.id);
    return ids;
  }, [selectedCommittees]);

  const handleSearch = (): void => {
    setQuery({
      startDateRange: fromDate,
      endDateRange: toDate,
      committeeIds,
    });
    resetScroll();
  };

  const fromDateLabel = fromDate ? fromDate.toLocaleDateString() : 'Start';
  const toDateLabel = toDate ? toDate.toLocaleDateString() : 'End';
  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

  return (
    <div className="filters container-fluid m-3">
      <div className="row">
        {isFetchingCommittees && <Spinner animation="border" role="status" />}
        <CommitteeDropdown
          availableCommittees={uniqueCommittees}
          selectedCommittees={selectedCommittees}
          setSelectedCommittees={setSelectedCommittees}
        />
        <div className="row date-pickers">
          <DayPickerInputDropdown
            label={fromDateLabel}
            maxDate={toDate ?? maxDate}
            selectedDate={fromDate}
            setSelectedDate={setFromDate}
            setShowDropdown={setShowFromDatePicker}
            showDropdown={showFromDatePicker}
          />
          <span className="divider" />
          <DayPickerInputDropdown
            label={toDateLabel}
            maxDate={maxDate}
            minDate={fromDate}
            selectedDate={toDate}
            setSelectedDate={setToDate}
            setShowDropdown={setShowToDatePicker}
            showDropdown={showToDatePicker}
          />
        </div>
        <div className="d-inline-flex col-3 default-search-buttons">
          <button
            className="btn btn-sm btn-outline-secondary float-right btn-teal"
            id="staffer-data-search-button"
            onClick={handleSearch}
            type="submit"
          >
            {t('buttons.search.submitButtonText')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JurisdictionHearingsFilters;

import { BillStatus, LegislativeType } from '@enview/interface/types/Bill';
import { BillSearchParams, SortBy } from '@enview/interface/types/BillSearch';
import { ChamberType } from '@enview/interface/types/LegislativeSession';
import {
  InsightsPolicyTopic,
  InsightsPolicyTopicType,
} from '@enview/interface/types/bills/InsightsPolicyTopic';
import {
  ArrayParam,
  BooleanParam,
  DateParam,
  DecodedValueMap,
  DelimitedArrayParam,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  withDefault,
} from 'use-query-params';
import { CURRENT_SESSION_FILTER, JurisdictionSessionResult } from '../api/jurisdiction';
import { SortOrder } from '../models/SortOrder';
import { OptionType } from '../scenes/Shared/DropDowns/MultipleSelectDropDown';

const currentSessionDefault = {
  label: CURRENT_SESSION_FILTER,
  key: CURRENT_SESSION_FILTER,
};

export const defaultChamberSelection = [ChamberType.UPPER, ChamberType.LOWER];

export const chamberOptions: OptionType<string>[] = [
  { label: 'Upper Chamber', value: ChamberType.UPPER },
  { label: 'Lower Chamber', value: ChamberType.LOWER },
  { label: 'Both Chambers', value: ChamberType.BOTH },
];

type BillStatusOption = OptionType<string> & {
  applicableOnlyToJurisdictions?: string[];
};

// TODO: Move this logic to backend/interface so we don't have hardcoded values
// We should also update our jurisdictions table to include reference to country
export const statusOptions: BillStatusOption[] = [
  { label: 'Introduced', value: BillStatus.INTRODUCED },
  { label: 'Passed Upper', value: BillStatus.PASSED_UPPER },
  { label: 'Passed Lower', value: BillStatus.PASSED_LOWER },
  { label: 'Passed', value: BillStatus.PASSED },
  { label: 'Signed by Governor', value: BillStatus.GOVERNOR },
  { label: 'Became Law', value: BillStatus.LAW },
  {
    label: 'First Reading',
    value: BillStatus.FIRST_READING,
    applicableOnlyToJurisdictions: ['ng', 'za'],
  },
  {
    label: 'Second Reading',
    value: BillStatus.SECOND_READING,
    applicableOnlyToJurisdictions: ['ng', 'il', 'za'],
  },
  {
    label: 'Third Reading',
    value: BillStatus.THIRD_READING,
    applicableOnlyToJurisdictions: ['ng', 'il', 'za'],
  },
  {
    label: 'Referred to Committee',
    value: BillStatus.REFERRAL_COMMITTEE,
    applicableOnlyToJurisdictions: ['ng', 'il', 'za'],
  },
  {
    label: 'Reported out of Committee',
    value: BillStatus.REPORTED_OUT_OF_COMMITTEE,
    applicableOnlyToJurisdictions: ['ng', 'za'],
  },
  {
    label: 'Sent for Concurrence',
    value: BillStatus.CONCURRENCE,
    applicableOnlyToJurisdictions: ['ng', 'za'],
  },
  {
    label: 'Withdrawn',
    value: BillStatus.WITHDRAWAL,
    applicableOnlyToJurisdictions: ['ng', 'za'],
  },
  { label: 'Vetoed', value: BillStatus.VETOED },
];

export const defaultStatusSelection = statusOptions.map(
  (statusOption) => statusOption.value,
);

export const getStatusOptionsForJurisdictions = (
  selectedJurisdictions: string[],
): OptionType<string>[] => {
  return statusOptions.filter((statusOption) => {
    return (
      !statusOption.applicableOnlyToJurisdictions ||
      statusOption.applicableOnlyToJurisdictions.some((jurisdiction) =>
        selectedJurisdictions.includes(jurisdiction),
      )
    );
  });
};

export const defaultLegislativeTypeSelection = [
  LegislativeType.BILL,
  LegislativeType.RESOLUTION,
  LegislativeType.JOINT_RESOLUTION,
  LegislativeType.CONCURRENT_RESOLUTION,
  LegislativeType.PROCLAMATION,
  LegislativeType.PROPOSED_BILL,
  LegislativeType.APPROPRIATION,
  LegislativeType.APPOINTMENT,
  LegislativeType.OTHER,
];

export const legislativeTypeOptions: OptionType<string>[] = [
  { label: 'Bill', value: LegislativeType.BILL },
  { label: 'Resolution', value: LegislativeType.RESOLUTION },
  { label: 'Joint Resolution', value: LegislativeType.JOINT_RESOLUTION },
  { label: 'Concurrent Resolution', value: LegislativeType.CONCURRENT_RESOLUTION },
  { label: 'Proclamation', value: LegislativeType.PROCLAMATION },
  { label: 'Proposed Bill', value: LegislativeType.PROPOSED_BILL },
  { label: 'Appropriation', value: LegislativeType.APPROPRIATION },
  { label: 'Appointment', value: LegislativeType.APPOINTMENT },
  { label: 'Other', value: LegislativeType.OTHER },
];

export const getSessionOptions = (
  sessions: JurisdictionSessionResult[],
  includeJurisdictions?: boolean,
): OptionType<string>[] => {
  const sessionStrings: { label: string; key: string }[] = [currentSessionDefault];
  sessions.forEach((session) => {
    const key = `${session.jurisdiction}-${session.displayName}`;
    let label: string;
    if (includeJurisdictions) label = `${session.jurisdiction} ${session.displayName}`;
    else label = session.displayName;
    const isLabelInSessions = sessionStrings.find(
      (sessionOption) => sessionOption.label === label,
    );
    if (!isLabelInSessions) {
      sessionStrings.push({ key, label });
    }
  });
  return sessionStrings.map(({ key, label }) => {
    return { label, value: label, key };
  });
};

export const getInsightsPolicyTopicOptions = (
  topics: InsightsPolicyTopic[] | undefined,
): OptionType<string>[] => {
  const topicsStrings: { label: string; key: string; value: string }[] = [];
  topics?.forEach((topic) => {
    const label = topic.policyTopic;
    if (label != InsightsPolicyTopicType.UNTAGGABLE) {
      const key = `${topic.policyTopic}-${topic.id}`;
      const value = topic.insightName;
      topicsStrings.push({ key, label, value });
    }
  });
  return topicsStrings;
};

export const getSessionOptionsWithIds = (
  sessions: JurisdictionSessionResult[],
): OptionType<number>[] => {
  const sessionStrings: { label: string; key: string; value: number }[] = [
    {
      ...currentSessionDefault,
      value: -1,
    },
  ];
  sessions.forEach((session) => {
    const label = `${session.jurisdiction} ${session.displayName}`;
    const value = session.sessionId;
    const key = value.toString();
    const isLabelInSessions = sessionStrings.find(
      (sessionOption) => sessionOption.label === label,
    );
    if (!isLabelInSessions) {
      sessionStrings.push({ key, label, value });
    }
  });
  return sessionStrings.map(({ key, label, value }) => {
    return { label, value, key };
  });
};

export const getQueryParamConfig = (
  jurisdictionFilters: string[],
): QueryParamConfigMap => {
  return {
    keywords: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, undefined),
    order: withDefault(StringParam, null),
    status: withDefault(ArrayParam, defaultStatusSelection),
    sessions: withDefault(DelimitedArrayParam, [CURRENT_SESSION_FILTER]),
    jurisdictions: withDefault(DelimitedArrayParam, jurisdictionFilters),
    includeTrackedBills: withDefault(BooleanParam, true),
    chamber: withDefault(StringParam, ChamberType.BOTH),
    legislativeType: withDefault(ArrayParam, defaultLegislativeTypeSelection),
    searchActionType: withDefault(StringParam, undefined),
    searchActionFromDate: withDefault(DateParam, undefined),
    searchActionToDate: withDefault(DateParam, undefined),
    onlyShowMomentumBills: withDefault(BooleanParam, false),
    insightsPolicyTopic: withDefault(ArrayParam, undefined),
  };
};

export const buildParamsFromQuery = (
  query: DecodedValueMap<QueryParamConfigMap>,
  teamModeId: number,
  pageSize: number,
): BillSearchParams => {
  const chamberFilter =
    query.chamber === ChamberType.BOTH ? defaultChamberSelection : [query.chamber];

  const searchActionFilter =
    query.searchActionType && query.searchActionFromDate && query.searchActionToDate
      ? {
          type: query.searchActionType,
          fromDate: query.searchActionFromDate.toISOString(),
          toDate: query.searchActionToDate.toISOString(),
        }
      : undefined;

  const order = query.order
    ? SortOrder.getBillSortOrderFromParam(query.order)
    : new SortOrder(query.keywords ? SortBy.RELEVANCE : SortBy.RECENT, false);
  return {
    criteria: {
      jurisdictions: query.jurisdictions as string[],
      sessionNames: query.sessions as string[],
      statuses: query.status,
      chambers: chamberFilter,
      actionFilter: searchActionFilter,
      legislativeTypes: query.legislativeType,
      includeTracked: query.includeTrackedBills,
      salienceScore: query.onlyShowMomentumBills ? 1 : undefined,
      query: query.keywords,
      teamId: teamModeId,
      useDefaultSession: !query.sessions || query.sessions.length === 0,
      insightsPolicyTopics: query.insightsPolicyTopic as string[],
    },
    pagination: {
      page: query.page,
      pageSize,
      sortOrder: order,
    },
  };
};

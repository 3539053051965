import pick from 'lodash-es/pick';
import * as Sentry from '@sentry/react';
import { State } from '../dux/@types';

export const getAuthHeader = (): string | undefined => {
  const state =
    window.sessionStorage.getItem('state') || window.localStorage.getItem('state');
  if (!state) return undefined;
  const { authentication } = JSON.parse(state);
  if (!authentication || !authentication.authToken) return undefined;
  return `Bearer ${authentication.authToken}`;
};

export const getSessionId = (): string | undefined => {
  const state =
    window.sessionStorage.getItem('state') || window.localStorage.getItem('state');
  if (state) return JSON.parse(state).sessionId;
  return undefined;
};

export const persistStateInLocalStorage = (state: Partial<State>): void => {
  const propertiesToKeep = [
    'authentication.authToken',
    'teamMode.teamId',
    'teamMode.orgId',
    'authentication.isMasqueradeSession',
    'authentication.sessionId',
    'authentication.rememberMe',
  ];
  const localState = pick(state, propertiesToKeep);

  if (state.authentication?.sessionId) {
    Sentry.configureScope((scope) => {
      scope.setTag('transaction_id', state.authentication?.sessionId);
    });
  }

  // Assume that any state object without a sessionId or authToken is invalid and should not be stored
  if (localState.authentication?.sessionId || localState.authentication?.authToken) {
    // If "remember me" is set, write state into local storage (persists across browser tabs as well as between browser-close events)
    // Otherwise, write state to session storage (unique to each browser tab)
    if (state.authentication?.rememberMe) {
      window.localStorage.setItem('state', JSON.stringify(localState));
    } else {
      window.sessionStorage.setItem('state', JSON.stringify(localState));
    }
  }
};

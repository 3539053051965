import { Bill } from '@enview/interface/types/bills/Bill';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BillAPI, TeamAPI } from '../../../api';
import { getOrgIdForTeamMode } from '../../../dux';
import { State } from '../../../dux/@types';
import { t } from 'i18next';

type BulkTrackingContentProps = {
  bills: Bill[];
};

const BulkTrackingContent = (props: BulkTrackingContentProps): ReactElement => {
  const { bills } = props;
  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const teamModeOrgId = useSelector(getOrgIdForTeamMode);

  // Bulk actions
  const [bulkUntrackBills] = BillAPI.endpoints.bulkUntrackBills.useMutation();
  const [bulkTrackBills] = BillAPI.endpoints.bulkTrackBills.useMutation();

  const teams = useMemo(
    () =>
      (teamMemberships || [])
        .map((tm) => tm.team)
        .sort((team1, team2) => {
          const userTeamId = orgUser && orgUser.teamId;
          if (team1.id === userTeamId) {
            return -1;
          }
          if (team2.id === userTeamId) {
            return 1;
          }
          return team1.name.localeCompare(team2.name);
        })
        .filter(
          (team) =>
            team.organizationId === teamModeOrgId ||
            (orgUser && team.id === orgUser.teamId),
        ),
    [teamMemberships, teamModeOrgId],
  );

  const trackConfirmBody = t('bill.modals.trackText');
  return (
    <>
      <p className="popover-confirm-body">{trackConfirmBody}</p>
      {teams?.map((team) => {
        const checked =
          bills?.length > 0 &&
          bills?.every((bill) =>
            !!bill.teams
              ? bill?.teams?.length > 0 && bill?.teams?.some((a) => a.id === team.id)
              : false,
          );
        const striked =
          !checked &&
          bills?.length > 0 &&
          bills?.some((bill) =>
            !!bill.teams
              ? bill?.teams?.length > 0 && bill?.teams?.some((a) => a.id === team.id)
              : false,
          );
        return (
          <div key={`${team.id}-bulkdropdown-tracking`}>
            <label htmlFor={`${team.id}`}>
              <input
                checked={checked}
                id={`${team.id}`}
                name={`${team.name}`}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  e.target.checked
                    ? bulkTrackBills({ bills, teamId: team.id })
                    : bulkUntrackBills({ bills, teamId: team.id })
                }
                ref={(elem) => elem && (elem.indeterminate = striked)}
                type="checkbox"
              />
              <span className="label-text">{team.name}</span>
            </label>
          </div>
        );
      })}
    </>
  );
};

export default BulkTrackingContent;
